import { throwError as observableThrowError, Observable } from 'rxjs';
import {
    HttpInterceptor,
    HttpRequest,
    HttpHandler,
    HttpResponse,
    HttpEvent,
    HttpErrorResponse,
    HttpXsrfTokenExtractor
} from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';
import { Injectable } from '@angular/core';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/catch';
import { SnackbarUtil } from '../services/utils/snackbarUtil';
import { ServiceLocalStorage } from './local-storage.service';
import { RequestUtils } from '../services/utils/requestUtils';
import { AppConstants } from '../../constant/appconstants';
import { Router } from '@angular/router'
import { catchError, tap } from "rxjs/operators";
@Injectable({ providedIn: 'root' })
export class RequestResponseInterceptor implements HttpInterceptor {

    constructor(private router: Router,
        private serviceLocalStorage: ServiceLocalStorage,
        private requestUtils: RequestUtils,
        private snackBar: SnackbarUtil,
        private dialogRef: MatDialog,
        private tokenExtractor: HttpXsrfTokenExtractor,
        public snackbar: SnackbarUtil) { }
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let url = req.url;

        let loginUrl = this.requestUtils.getLoginUrl();
        let loginOtpUrl = this.requestUtils.userController().getLoginOtp;
        let addTempUser = this.requestUtils.addTempUserUrl();
        let getTempUser = this.requestUtils.getTempUserUrl();
        let getLocationListUrl = this.requestUtils.getLocationListUrl();
        let getSitesListByLocationIdUrl = this.requestUtils.getSitesListByLocationIdUrl();
        let getAreasListByLocationIdUrl = this.requestUtils.getAreasListByLocationIdUrl();
        let getLandmarksListByAreaIdUrl = this.requestUtils.getLandmarksListByAreaUrl();
        let getUserById = this.requestUtils.getUserByIdUrl();
        let userRegistration = this.requestUtils.userRegistrationUrl();
        let updateUse = this.requestUtils.updateUserUrl();

        let forgotpassword = this.requestUtils.forgotPasswordUrl();

        // let requestMethod: string = req.method;
        const requestMethod: string = req.method.toLowerCase();

        // if (requestMethod && (requestMethod === 'get' || requestMethod === 'post' || requestMethod === 'delete' || requestMethod === 'put')) {
        //     const headerName = 'X-XSRF-TOKEN';
        //     let token = this.tokenExtractor.getToken() as string;
        //     if (token !== null && !req.headers.has(headerName)) {
        //         req = req.clone({ headers: req.headers.set(headerName, token) });
        //     }
        // }



        if ((!url.includes(loginUrl) && (!url.includes(loginOtpUrl)) && !url.includes(addTempUser) && !url.includes(getTempUser) && !url.includes(getLocationListUrl) &&
            !url.includes(getSitesListByLocationIdUrl) && !url.includes(getAreasListByLocationIdUrl) && !url.includes(getLandmarksListByAreaIdUrl) && !url.includes(getUserById) && !url.includes(userRegistration) && !url.includes(updateUse) && !url.includes(forgotpassword) && url.includes("/atom/")) || (url.includes("/livetrack/api/")) || (url.includes("/atombilling/"))) {
            req = req.clone({
                setHeaders: { Authorization: AppConstants.BEARER_STRING + this.serviceLocalStorage.getAuthToken() }
            });
        }
        return next.handle(req).pipe(tap((event: HttpEvent<any>) => {
            if (event instanceof HttpResponse) {
                //Take token form login api response and store it
                if (event.status === 200 && (url === loginUrl)) {
                    const bearerToken = event.headers.get(AppConstants.AUTH_TOKEN_STRING);
                    if (bearerToken !== null && bearerToken.length > 0 && bearerToken.slice(0, AppConstants.BEARER_STRING.length) === AppConstants.BEARER_STRING) {
                        this.serviceLocalStorage.setAuthToken(bearerToken.slice(AppConstants.BEARER_STRING.length, bearerToken.length));
                    } else {
                        console.error("Unable to fetch token");
                    }
                }
            }
        }), catchError((response: any) => {
            return this.handleError(response);
        }));
    }
    /**
     * Handle errors and give proper meaning msg to the user
     * @param error : Returns meaningful msg
     */
    handleError(error: HttpErrorResponse) {
        let errorData = {
            message: '',
            status: 0
        };
        if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            errorData.message = "A client-side or network error occurred.";
        } else {
            if (error.status == 400) {
                if (error.error.message == 'Malformed Token') {
                    errorData.message = "Session Expired, Please login again.";
                    this.router.navigate(['/auth']);
                } else if (error.error.message == 'Not Subscribed') {
                    errorData = error.error;
                } else if (error.error.message == 'Check ToDate') {
                    errorData = error;
                } else if (error.error.message == 'Setup Already Exists') {
                    errorData.message = 'Setup Already Exists';
                    return observableThrowError(errorData);
                } else if (error.error.message == 'Setup Not Found') {
                    errorData.message = 'Setup Not Found';
                    return observableThrowError(errorData);
                } else {
                    errorData.message = error.error ? error.error.message ? error.error.message : error.error.error.message : "Empty error..";
                    this.snackbar.openSnackBar(errorData.message, null);
                }
            } else if (error.status == 401) {
                if (error.error.message === "Session Expired") {
                    errorData.message = error.error.message;
                    this.serviceLocalStorage.deleteAll();
                    this.router.navigate(['/auth'])
                    this.snackbar.openSnackBar("Session Expired, Please login again", null);
                } else if (error.error.message == "Authentication Failed: Bad credentials") {
                    errorData.message = "Bad-Credentials";
                    if (localStorage.getItem('checkuidPw') == 'true') {
                        errorData.message = "Wrong UserName / Password";
                        return observableThrowError(errorData);
                    }
                } else if (error.error.message == "Authentication Failed: Not able to find tenant DB") {
                    errorData.message = "Wrong Client Code";
                    return observableThrowError(errorData);
                } else if (error.error.message == "Authentication Failed: Not able to find tenant DB") {
                    errorData.message = "Wrong UserName / Password";
                    return observableThrowError(errorData);
                } else if (error.error.message == "Account blocked!") {
                    errorData.message = "Account blocked due to multiple attempts. Please try again after 15 mins";
                    return observableThrowError(errorData);
                } else if (error.error.message == "User is disabled") {
                    errorData.message = "User is disabled. Please Contact Admin"
                    return observableThrowError(errorData);
                    this.snackbar.openSnackBar(errorData.message, null);
                } else if (error.error.message === "Expired Token") {
                    errorData.message = "Expired Token";
                } else if (error.error.message.includes("Authentication Failed: SSO Enabled :")) {
                    return observableThrowError(error.error.message);
                    // } else if (error.error.message === "Authentication Failed: SSO-MFA Enabled") {
                    //     localStorage.setItem('mfaEnabled', 'true');
                    //     return observableThrowError(error.error.message);
                    // } else if (error.error.message === "Authentication Failed: MFA Enabled") {
                    //     localStorage.setItem('mfaEnabled', 'true');
                    //     return observableThrowError(error.error.message);
                } else {
                    errorData.message = "Unauthourized access";
                }
            } else if (error.status == 403) {
                errorData.message = "Error in getAuthentication";
            } else if (error.status == 404) {
                errorData.message = "Server not found";
            } else if (error.status == 406) {
                errorData.message = error.error.message;
            } else if (error.status == 409) {
                errorData.message = error.error.message;
            } else if (error.status == 500) {
                errorData.message = "Server error";
                errorData.status = error.status;
            } else if (error.status == 504) {
                errorData.message = "Request Timed out, Please try again later";
                errorData.status = error.status;
            } else {
                errorData.message = "Network error occured. Please try again later.";
            }
        }
        return observableThrowError(errorData);
    }
}