<!-- <div class="topnav"> 
   <div class="container-fluid">
    <nav class="navbar navbar-light navbar-expand-lg topnav-menu active">
      <div class="collapse navbar-collapse active" id="topnav-menu-content">
        <ul class="navbar-nav">
          <ng-container *ngFor="let item of menuItems">
            <li class="nav-item dropdown" *ngIf="item.permission">
              <a class="nav-link dropdown-toggle arrow-none" *ngIf="!item.subItems" href="javascript: void(0);"
                id="topnav-components" [routerLink]="item.link" role="button">
                <i class="bx {{item.icon}} me-2" style="position: relative; top: 2px;"></i>{{ item.label | translate }} <div class="arrow-down"
                  *ngIf="hasItems(item)"></div>
              </a>

               <a *ngIf="item.subItems" class="nav-link dropdown-toggle arrow-none" (click)="onMenuClick($event)"
                href="javascript: void(0);" id="topnav-components" role="button">
                <i class="bx {{item.icon}} me-1" style="position: relative; top: 2px;"></i>
                {{item.label| translate }}
                <div class="arrow-down"></div>
              </a>

              <div class="dropdown-menu" [ngClass]="{'dropdown-mega-menu-xl px-2':  item.subItems.length > 11}" aria-labelledby="topnav-dashboard" *ngIf="hasItems(item)">
                <ng-template ngFor let-i="index" let-subitem [ngForOf]="item.subItems">
                  <a *ngIf="item.subItems.length < 11 && !hasItems(subitem) && subitem.permission" class="col dropdown-item side-nav-link-ref"
                  [routerLink]="subitem.link"
                  routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                   {{subitem.label | translate }}
              </a>
              <div *ngIf="item.subItems.length > 11">
                  <div *ngIf="i % 3 == 0" class="row">
                      <div class="col-lg-4"><a class="dropdown-item side-nav-link-ref" routerLinkActive="active" [routerLink]="subitem.link">{{item.subItems[i].label | translate}}</a></div>
                      <div class="col-lg-4"><a class="dropdown-item side-nav-link-ref" routerLinkActive="active" [routerLink]="item.subItems[i + 1]?.link">{{item.subItems[i + 1]?.label | translate}}</a></div>
                      <div class="col-lg-4"><a class="dropdown-item side-nav-link-ref" routerLinkActive="active" [routerLink]="item.subItems[i + 2]?.link">{{item.subItems[i + 2]?.label | translate}}</a></div>
                  </div>
              </div>
              
                <div class="dropdown" *ngIf="hasItems(subitem)">
                  <a class="dropdown-item dropdown-toggle" href="javascript: void(0);"
                    (click)="onMenuClick($event)">{{ subitem.label | translate }}
                    <div class="arrow-down"></div>
                  </a>
                  <div class="dropdown-menu">
                    <ng-template ngFor let-subSubitem [ngForOf]="subitem.subItems">
                      <a class="dropdown-item side-nav-link-ref" *ngIf="!hasItems(subSubitem)"
                        href="javascript: void(0);" [routerLink]="subSubitem.link"
                        routerLinkActive="active">{{ subSubitem.label | translate }}</a>
                      <div class="dropdown" *ngIf="hasItems(subSubitem)">

                        <a class="dropdown-item dropdown-toggle" href="javascript: void(0);"
                          (click)="onMenuClick($event)">{{ subSubitem.label | translate }}
                          <div class="arrow-down"></div>
                        </a>
                        <div class="dropdown-menu">
                          <ng-template ngFor let-subSubSubitem [ngForOf]="subSubitem.subItems">
                            <a class="dropdown-item side-nav-link-ref" href="javascript: void(0);"
                              [routerLink]="subSubSubitem.link"
                              routerLinkActive="active">{{ subSubSubitem.label | translate }}</a>
                          </ng-template>
                        </div>
                      </div>
                    </ng-template>
                  </div>
                </div>
              </ng-template>
             </div>
            </li>
          </ng-container>
        </ul>
      </div>
    </nav>
  </div>
</div>  -->

<div class="topnav">
  <div class="container-fluid">
    <nav class="navbar navbar-light navbar-expand-lg topnav-menu active">
      <div class="collapse navbar-collapse active" id="topnav-menu-content">
        <ul class="navbar-nav" style="position: relative; left: 10px;">
          <ng-container *ngFor="let item of menuItems">
            <li class="nav-item dropdown">
              <a class="nav-link dropdown-toggle arrow-none" *ngIf="!item.subItems && item.permission"
                href="javascript: void(0);" id="topnav-components" [routerLink]="item.link" role="button">
                <i class="bx {{item.icon}} me-2" style="position: relative; top: 2px;"></i>{{ item.label | translate }}<div class="arrow-down" *ngIf="hasItems(item)"></div>
              </a>

              <a *ngIf="item.subItems && item.permission" class="nav-link dropdown-toggle arrow-none"
                href="javascript: void(0);" id="topnav-components" role="button">
                <i class="bx {{item.icon}} me-2" style="position: relative; top: 2px;"></i>{{item.label | translate }}
              </a>

              <div class="dropdown-menu" [ngClass]="{'dropdown-mega-menu-xl px-2':  item.subItems.length > 12}"
                aria-labelledby="topnav-dashboard" *ngIf="hasItems(item)">
                <ng-template ngFor let-i="index" let-subitem [ngForOf]="item.subItems">
                  <a *ngIf="item.subItems.length < 13 && !hasItems(subitem) && subitem.permission && !subitem.link"
                    class="col dropdown-item side-nav-link-ref" [routerLink]="subitem.link" role="button">
                    {{subitem.label | translate }}
                  </a>

                  <a *ngIf="item.subItems.length < 13 && !hasItems(subitem) && subitem.permission && subitem.link"
                    class="col dropdown-item side-nav-link-ref" [routerLink]="subitem.link" routerLinkActive="active"
                    [routerLinkActiveOptions]="{exact: true}">
                    {{subitem.label | translate }}
                  </a>

                  <div *ngIf="item.subItems.length > 12">
                    <div *ngIf="i % 3 == 0" class="row">
                      <div class="col-lg-4" *ngIf="item.subItems[i].permission"><a
                          class="dropdown-item side-nav-link-ref" routerLinkActive="active"
                          [routerLink]="subitem.link">{{item.subItems[i].label | translate}}</a></div>
                      <div class="col-lg-4" *ngIf="item.subItems[i +1].permission"><a
                          class="dropdown-item side-nav-link-ref" routerLinkActive="active"
                          [routerLink]="item.subItems[i + 1]?.link">{{item.subItems[i + 1]?.label | translate}}</a>
                      </div>
                      <div class="col-lg-4" *ngIf="item.subItems[i +2].permission"><a
                          class="dropdown-item side-nav-link-ref" routerLinkActive="active"
                          [routerLink]="item.subItems[i + 2]?.link">{{item.subItems[i + 2]?.label | translate}}</a>
                      </div>
                    </div>
                  </div>

                  <div class="dropdown" *ngIf="hasItems(subitem)">
                    <a class="dropdown-item dropdown-toggle" href="javascript: void(0);" *ngIf="subitem.permission"
                      (click)="onMenuClick($event)">{{ subitem.label | translate }}
                      <div class="arrow-down"></div>
                    </a>
                    <div class="dropdown-menu">
                      <ng-template ngFor let-subSubitem [ngForOf]="subitem.subItems">
                        <a class="dropdown-item side-nav-link-ref"
                          *ngIf="!hasItems(subSubitem) && subSubitem.permission" href="javascript: void(0);"
                          [routerLink]="subSubitem.link" routerLinkActive="active">{{
                          subSubitem.label | translate }}</a>
                        <div class="dropdown" *ngIf="hasItems(subSubitem)">
                          <a class="dropdown-item dropdown-toggle" href="javascript: void(0);"
                            *ngIf="subSubitem.permission" (click)="onMenuClick($event)">{{ subSubitem.label |
                            translate }}
                            <div class="arrow-down"></div>
                          </a>
                          <div class="dropdown-menu">
                            <ng-template ngFor let-subSubSubitem [ngForOf]="subSubitem.subItems">
                              <a class="dropdown-item side-nav-link-ref" href="javascript: void(0);"
                                *ngIf="subSubSubitem.permission" [routerLink]="subSubSubitem.link"
                                routerLinkActive="active">{{ subSubSubitem.label | translate }}</a>
                            </ng-template>
                          </div>
                        </div>
                      </ng-template>
                    </div>
                  </div>
                </ng-template>
              </div>
            </li>
          </ng-container>
        </ul>
      </div>
    </nav>
  </div>
</div>