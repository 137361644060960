import { Component, OnInit, AfterViewInit, Inject } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';

import { DOCUMENT } from '@angular/common';

import { MENU } from './menu';
import { MenuItem } from './menu.model';
import { environment } from '../../../environments/environment';
import { ServiceLocalStorage } from 'src/app/core/services/local-storage.service';
import { ServiceAuth } from 'src/app/core/services/api/auth.service';

@Component({
  selector: 'app-horizontaltopbar',
  templateUrl: './horizontaltopbar.component.html',
  styleUrls: ['./horizontaltopbar.component.scss']
})

/**
 * Horizontal Topbar and navbar specified
 */
export class HorizontaltopbarComponent implements OnInit, AfterViewInit {

  element;
  cookieValue;
  flagvalue;
  countryName;
  valueset;

  menuItems: any[] = [];

  _currentUser: any;
  userInfoDetail: any;
  _rolePermissions: any;

  listLang = [
    { text: 'English', flag: 'assets/images/flags/us.jpg', lang: 'en' },
    { text: 'Spanish', flag: 'assets/images/flags/spain.jpg', lang: 'es' },
    { text: 'German', flag: 'assets/images/flags/germany.jpg', lang: 'de' },
    { text: 'Italian', flag: 'assets/images/flags/italy.jpg', lang: 'it' },
    { text: 'Russian', flag: 'assets/images/flags/russia.jpg', lang: 'ru' },
  ];

  // tslint:disable-next-line: max-line-length
  constructor(@Inject(DOCUMENT) private document: any, private router: Router,
    public _cookiesService: CookieService,
    public readonly serviceLocalStorage: ServiceLocalStorage,
    public serviceAuth: ServiceAuth,) {
    router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.activateMenu();
      }
    });
  }

  ngOnInit(): void {
    if (this.serviceLocalStorage.hasAuthToken()) {
      this.serviceAuth.getCurrentUser(success => {
        this._currentUser = success.body;
        this.userInfoDetail = success.body.userInfoDetails;
        this.getRolePermission();
      }, error => {
        console.log(error)
      });
    }

    this.element = document.documentElement;
    this.cookieValue = this._cookiesService.get('lang');
    const val = this.listLang.filter(x => x.lang === this.cookieValue);
    this.countryName = val.map(element => element.text);
    if (val.length === 0) {
      if (this.flagvalue === undefined) { this.valueset = 'assets/images/flags/us.jpg'; }
    } else {
      this.flagvalue = val.map(element => element.flag);
    }

  }

  setLanguage(text: string, lang: string, flag: string) {
    this.countryName = text;
    this.flagvalue = flag;
    this.cookieValue = lang;
  }

  /**
   * On menu click
   */
  onMenuClick(event) {
    const nextEl = event.target.nextElementSibling;
    if (nextEl) {
      const parentEl = event.target.parentNode;
      if (parentEl) {
        parentEl.classList.remove("show");
      }
      nextEl.classList.toggle("show");
    }
    return false;
  }

  ngAfterViewInit() {
    this.activateMenu();
  }

  /**
   * remove active and mm-active class
   */
  _removeAllClass(className) {
    const els = document.getElementsByClassName(className);
    while (els[0]) {
      els[0].classList.remove(className);
    }
  }

  /**
   * Togglemenu bar
   */
  toggleMenubar() {
    const element = document.getElementById('topnav-menu-content');
    element.classList.toggle('show');
  }

  /**
   * Activates the menu
   */
  private activateMenu() {

    const resetParent = (el: any) => {
      const parent = el.parentElement;
      if (parent) {
        parent.classList.remove('active');
        const parent2 = parent.parentElement;
        this._removeAllClass('mm-active');
        this._removeAllClass('mm-show');
        if (parent2) {
          parent2.classList.remove('active');
          const parent3 = parent2.parentElement;
          if (parent3) {
            parent3.classList.remove('active');
            const parent4 = parent3.parentElement;
            if (parent4) {
              parent4.classList.remove('active');
              const parent5 = parent4.parentElement;
              if (parent5) {
                parent5.classList.remove('active');
                const menuelement = document.getElementById("topnav-menu-content")
                if (menuelement !== null) {
                  if (menuelement.classList.contains('show'))
                    document
                      .getElementById("topnav-menu-content")
                      .classList.remove("show");
                }
              }
            }
          }
        }
      }
    };

    // activate menu item based on location
    const links = document.getElementsByClassName('side-nav-link-ref');
    let matchingMenuItem = null;
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < links.length; i++) {
      // reset menu
      resetParent(links[i]);
    }
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < links.length; i++) {
      // tslint:disable-next-line: no-string-literal
      if (location.pathname === links[i]['pathname']) {
        matchingMenuItem = links[i];
        break;
      }
    }

  }

  onClick(item) { }

  /**
   * on settings button clicked from topbar
   */
  onSettingsButtonClicked() {
    document.body.classList.toggle('right-bar-enabled');
  }

  /**
   * Fullscreen method
   */
  fullscreen() {
    document.body.classList.toggle('fullscreen-enable');
    if (
      !document.fullscreenElement && !this.element.mozFullScreenElement &&
      !this.element.webkitFullscreenElement) {
      if (this.element.requestFullscreen) {
        this.element.requestFullscreen();
      } else if (this.element.mozRequestFullScreen) {
        /* Firefox */
        this.element.mozRequestFullScreen();
      } else if (this.element.webkitRequestFullscreen) {
        /* Chrome, Safari and Opera */
        this.element.webkitRequestFullscreen();
      } else if (this.element.msRequestFullscreen) {
        /* IE/Edge */
        this.element.msRequestFullscreen();
      }
    } else {
      if (this.document.exitFullscreen) {
        this.document.exitFullscreen();
      } else if (this.document.mozCancelFullScreen) {
        /* Firefox */
        this.document.mozCancelFullScreen();
      } else if (this.document.webkitExitFullscreen) {
        /* Chrome, Safari and Opera */
        this.document.webkitExitFullscreen();
      } else if (this.document.msExitFullscreen) {
        /* IE/Edge */
        this.document.msExitFullscreen();
      }
    }
  }

  getRolePermission() {
    this.getRolePermissions(this.userInfoDetail.role.id).then(value => {
      this._rolePermissions = this.serviceLocalStorage.getPermissionObject();
      this.initialize();
    });
  }

  getRolePermissions(roleId) {
    return new Promise(resolve => {
      this.serviceAuth.getRolePermissions(roleId, success => {
        if (success.status == 200) {
          this.serviceLocalStorage.setPermissionObject(success.body);
          resolve(null);
        }
      }, error => {
        resolve(error);
      });
    });
  }

  /**
   * Initialize
   */
  initialize(): void {
    this.menuItems = [
      {
        id: 1,
        label: 'Dashboard',
        icon: 'bx-home-circle',
        link: '/billing/dashboard',
        permission: this._rolePermissions.billingDashboard,
        // subItems: [
        //   {
        //     id: 4,
        //     label: 'Trip',
        //     link: '/billing/dashboard/trip',
        //     parentId: 1,
        //     permission: this._rolePermissions.billingTripDashboard,
        //   },
        //   {
        //     id: 5,
        //     label: 'Employee',
        //     link: '/billing/dashboard/employee',
        //     parentId: 1,
        //     permission: this._rolePermissions.billingEmpDashboard,
        //   },
        // ]
      },
      {
        id: 2,
        label: 'Billing Setup',
        icon: 'bx bx-money',
        link: '/billing/setup',
        permission: this._rolePermissions.billingSetup,
        subItems: [
          {
            id: 6,
            label: 'General Billing',
            link: '/billing/setup/general',
            parentId: 1,
            permission: this._rolePermissions.billingGenSetup,
          },
          {
            id: 7,
            label: 'Oncall Billing',
            link: '/billing/setup/oncall',
            parentId: 1,
            permission: this._rolePermissions.billingOncallSetup,
          },
        ]
      },
      {
        id: 3,
        label: 'Billing Reports',
        icon: 'bx bxs-report',
        link: '/billing/reports',
        permission: this._rolePermissions.billingReport,
        subItems: [
          {
            id: 8,
            label: 'General Billing',
            link: '/billing/reports/general',
            parentId: 3,
            permission: this._rolePermissions.billingGenReport,
          },
          {
            id: 9,
            label: 'Oncall Billing',
            link: '/billing/reports/oncall',
            parentId: 3,
            permission: this._rolePermissions.billingOncallReport,
          },
        ]
      },
    ];
  }

  /**
   * Returns true or false if given menu item has child or not
   * @param item menuItem
   */
  hasItems(item: MenuItem) {
    return item.subItems !== undefined ? item.subItems.length > 0 : false;
  }

}
