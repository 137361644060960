/** @description Declare and initialize all the constant for the app here.  
*/
export class AppConstants {
    public static AUTH_TOKEN_STRING: string = "Authorization";
    public static BEARER_STRING: string = "Bearer ";//One blank space at last is needed, dont delete

    public static ACTIVE_STATUS: string = "Active";
    public static DEACTIVE_STATUS: string = "Deactive";

    public static SUBSCRIBE_STATUS: string = "Subscribed";
    public static UNSUBSCRIBE_STATUS: string = "Unsubscribed";
    public static PENDING_STATUS: string = "Pending";
    public static APPROVED_STATUS: string = "Approved";
    public static CANCELED_STATUS: string = "Canceled"; 
    public static Authentication_Type_Email: string = "Email-Verification";


    public static APPROVAL_TYPE_YES: string = "Yes";
    public static APPROVAL_TYPE_NO: string = "No";

    public static SCHEDULE_CANCEL_MODE_AUTOMATIC: string = "Automatic";
    public static SCHEDULE_CANCEL_MODE_MANUAL: string = "Manual";

    public static PICK_UP: string = "PickUp";
    public static DROP: string = "Drop";

    public static VALIDATION_TYPE_API: string = "api";
    public static VALIDATION_TYPE_LOCAL: string = "local";

    public static BOOKING_TYPE_GENERAL: string = "General";
    public static BOOKING_TYPE_ADHOC: string = "Adhoc";

    public static LANDMARK_TYPE_HOME: string = "H";
    public static LANDMARK_TYPE_COMMON: string = "C";

    public static TABLE_OPTIONS = ["Edit"];
    public static TABLE_OPTIONS_MORE = ["Edit", "More"];
    public static PICK_DROP_ARRAY = ["PickUp", "Drop"]
    public static APPROVAL_ARRAY = ["Yes", "No"]
    public static SCHEDULE_CANCEL_MODE_ARRAY = ["Automatic", "Manual"]
    public static ACTIVE_DEACTIVE_ARRAY = ["Active", "Deactive"]
    public static VEHICLES_DAY_FILTER = [{ key: 'Expired', value: 0 }, { key: '15 Days', value: 15 }
        , { key: '30 Days', value: 30 }, { key: '45 Days', value: 45 }, { key: '60 Days', value: 60 }];
    public static DRIVERS_DAY_FILTER = [{ key: '15 Days', value: 15 }
        , { key: '30 Days', value: 30 }, { key: '45 Days', value: 45 }, { key: '60 Days', value: 60 }];

    public static EDIT_DROP = "Edit";

    public static BLOOD_GROUPS = ["A+", "A-", "B+", "B-", "O+", "O-", "AB+", "AB-"]

    public static GOVT_ID_Proof = ["Aadhaar card", "PAN card", "Driving licence", "Ration card", "Indian passport"];

    public static MORE_DROP = "More";
    public static ACTIVATE_MSG = "Activate";
    public static DEACTIVATE_MSG = "Deactivate";
    public static DEFAULT_IMG = "assets/img/default_img.png";
    public static ALL_RECORDS = "ALL";
    public static PAGE_SIZE = 20;
    public static reportingTimes = [{ "value": "00:15" }, { "value": "00:30" }, { "value": "00:45" }, { "value": "01:00" }
        , { "value": "01:15" }, { "value": "01:30" }, { "value": "01:45" }, { "value": "02:00" },
    { "value": "02:15" }, { "value": "02:30" }, { "value": "02:45" }, { "value": "03:00" },
    { "value": "03:15" }, { "value": "03:30" }, { "value": "03:45" }, { "value": "04:00" },
    { "value": "04:15" }, { "value": "04:30" }, { "value": "04:45" }, { "value": "05:00" },
    { "value": "05:15" }, { "value": "05:30" }, { "value": "05:45" }, { "value": "06:00" },
    { "value": "06:15" }, { "value": "06:30" }, { "value": "06:45" }, { "value": "07:00" }
        , { "value": "07:15" }, { "value": "07:30" }, { "value": "07:45" }, { "value": "08:00" },
    { "value": "08:15" }, { "value": "08:30" }, { "value": "08:45" }, { "value": "09:00" },
    { "value": "09:15" }, { "value": "09:30" }, { "value": "09:45" }, { "value": "10:00" },
    { "value": "10:15" }, { "value": "10:30" }, { "value": "10:45" }, { "value": "11:00" },
    { "value": "11:15" }, { "value": "11:30" }, { "value": "11:45" }, { "value": "12:00" },
    { "value": "12:15" }, { "value": "12:30" }, { "value": "12:45" }, { "value": "13:00" }
        , { "value": "13:15" }, { "value": "13:30" }, { "value": "13:45" }, { "value": "14:00" },
    { "value": "14:15" }, { "value": "14:30" }, { "value": "14:45" }, { "value": "15:00" },
    { "value": "15:15" }, { "value": "15:30" }, { "value": "15:45" }, { "value": "16:00" },
    { "value": "16:15" }, { "value": "16:30" }, { "value": "16:45" }, { "value": "17:00" },
    { "value": "17:15" }, { "value": "17:30" }, { "value": "17:45" }, { "value": "18:00" },
    { "value": "18:15" }, { "value": "18:30" }, { "value": "18:45" }, { "value": "19:00" }
        , { "value": "19:15" }, { "value": "19:30" }, { "value": "19:45" }, { "value": "20:00" },
    { "value": "20:15" }, { "value": "20:30" }, { "value": "20:45" }, { "value": "21:00" },
    { "value": "21:15" }, { "value": "11:30" }, { "value": "21:45" }, { "value": "22:00" },
    { "value": "22:15" }, { "value": "22:30" }, { "value": "22:45" }, { "value": "23:00" },
    { "value": "23:15" }, { "value": "23:30" }, { "value": "23:45" }]

    public static Hours = [{ "value": "00" }, { "value": "01" }, { "value": "02" }, { "value": "03" }
        , { "value": "04" }, { "value": "05" }, { "value": "06" }, { "value": "07" },
    { "value": "08" }, { "value": "09" }, { "value": "10" }, { "value": "11" },
    { "value": "12" }, { "value": "13" }, { "value": "14" }, { "value": "15" },
    { "value": "16" }, { "value": "17" }, { "value": "18" }, { "value": "19" },
    { "value": "20" }, { "value": "21" }, { "value": "22" }, { "value": "23" }]

    public static Minutes = [{ "value": "00" }, { "value": "01" }, { "value": "02" }, { "value": "03" }
        , { "value": "04" }, { "value": "05" }, { "value": "06" }, { "value": "07" },
    { "value": "08" }, { "value": "09" }, { "value": "10" }, { "value": "11" },
    { "value": "12" }, { "value": "13" }, { "value": "14" }, { "value": "15" },
    { "value": "16" }, { "value": "17" }, { "value": "18" }, { "value": "19" },
    { "value": "20" }, { "value": "21" }, { "value": "22" }, { "value": "23" },
    { "value": "24" }, { "value": "25" }, { "value": "26" }, { "value": "27" }
        , { "value": "28" }, { "value": "29" }, { "value": "30" }, { "value": "31" },
    { "value": "32" }, { "value": "33" }, { "value": "34" }, { "value": "35" },
    { "value": "36" }, { "value": "37" }, { "value": "38" }, { "value": "39" },
    { "value": "40" }, { "value": "41" }, { "value": "42" }, { "value": "43" },
    { "value": "44" }, { "value": "45" }, { "value": "46" }, { "value": "47" },
    { "value": "48" }, { "value": "49" }, { "value": "50" }, { "value": "51" },
    { "value": "52" }, { "value": "53" }, { "value": "54" }, { "value": "55" },
    { "value": "56" }, { "value": "57" }, { "value": "58" }, { "value": "59" }]

    public static Years = [{ "value": "2019" }, { "value": "2020" }, { "value": "2021" },
    { "value": "2022" }, { "value": "2023" }, { "value": "2024" }, { "value": "2025" }, { "value": "2026" }, { "value": "2027" }, { "value": "2028" }, { "value": "2029" },
    { "value": "2030" }]

    public static Months = [{ key: "All", value: "All" }, { key: "01", value: "January" }, { key: "02", value: "February" }, { key: "03", value: "March" }, { key: "04", value: "April" }
        , { key: "05", value: "May" }, { key: "06", value: "June" }, { key: "07", value: "July" }, { key: "08", value: "August" }, { key: "09", value: "September" },
    { key: "10", value: "October" }, { key: "11", value: "November" }, { key: "12", value: "December" }]

    public static osrmUrl = "";

}