<div id="layout-wrapper">
  <!-- <app-horizontaltopbar></app-horizontaltopbar> -->
  <app-topbar></app-topbar>
  <app-horizontaltopbar></app-horizontaltopbar>

  <!-- <div class="main-content"> -->
    <div class="layout-outlet">
      <router-outlet></router-outlet>
    </div>
  <!-- </div> -->
</div>
