import { Injectable } from '@angular/core';
import { DbService } from './../db.service';
import { RequestUtils } from './../utils/requestUtils';

@Injectable({ providedIn: 'root' })
export class ServiceAuth {

  constructor(private dbService: DbService, private requestUtils: RequestUtils) { }

  /**
   * doLogin(): Function to invoke login api
   * @param data : request data to pass through the api
   * @param successCallBack : call back function for handling success api calls
   * @param errorCallBack : call back function for handling failed api calls
   */
  doLogin(data, successCallBack, errorCallBack) {
    let loginUrl = this.requestUtils.getLoginUrl();
    this.dbService.doPostWithEmailResponse(loginUrl, data,
      response => { successCallBack(response) },
      error => { errorCallBack(error) });
  }

  getLoginOtp(userName, clientCode, successCallBack, errorCallBack) {
    let url = RequestUtils.BASE_URL + this.requestUtils.userController().getLoginOtp;
    let data = userName;
    this.dbService.doPostWithEmailHead2(url, data, clientCode,
      response => { successCallBack(response) },
      error => { errorCallBack(error) });
  }

  getBillingAccess(successCallBack,errorCallBack) {
    let url = RequestUtils.BASE_URL + this.requestUtils.billingController().getBillingAccess;
    this.dbService.doGetWithParamsAndResponse(url, '',
      response => { successCallBack(response); },
      error => { errorCallBack(error); });

  }

  getRolePermissions(roleId,successCallBack,errorCallBack) {
    let url = RequestUtils.BASE_URL + this.requestUtils.userController().getRolePermissions+roleId;
    this.dbService.doGetWithParamsAndResponse(url, '',
      response => { successCallBack(response); },
      error => { errorCallBack(error); });
  }

  getCurrentUser(successCallBack,errorCallBack) {
    let url = RequestUtils.BASE_URL + this.requestUtils.userController().getCurrentUser;
    this.dbService.doGetWithParamsAndResponse(url, '',
      response => { successCallBack(response); },
      error => { errorCallBack(error); });
  }
}