import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import 'rxjs/add/operator/map';
const SECRET_KEY: any = 'STLw7oTB61us';
import SecureStorage from 'secure-web-storage';
import * as CryptoJS from 'crypto-js';

@Injectable({ providedIn: "root" })
export class ServiceLocalStorage {
  private permissionSubj = new Subject();
  AUTH_TOKEN = "auth-token";
  authToken: any = undefined;

  ROLE_ID = "role-id";
  roleid: any;

  ROLE_Name = "role-name";
  roleName: any;

  SITE_ID = "site-id";
  siteid: any;

  COMPANY_ID = "company-id";
  companyid: any;

  COMPANY_WeeklyOff = "company-weeklyoff";
  companyweeklyoff: any;

  DATABASE_NAME = 'database-name';
  databasename: any;

  LOCATION_ID = "location-id";
  locationid: any;

  USER_ID = "user-id";
  userid: any;

  CURRENT_PAGE_NUMBER = "current-page-number";
  currentpagenumber: any;

  REQUEST_ID = "request-id";
  requestid: any;

  PERMISSION_OBJECT = "permission-object";
  permissionobject: any = {};

  MAP_TYPE = "map-type";
  maptype: any;

  TRIP_GEN_DATA = "trip_gen_data";
  tripgendata: any;

  _mRouteName = "route-name";
  routeName: any;

  _mSubRouteName = "sub-route-name";
  subRouteName: any;

  USER_OBJECT = "user-object";
  userobject: any;

  USER_EDIT_ID = "user-edit-id";
  usereditid: any;

  currentUser: any;

  constructor() { }


  // public secureStorage = new SecureStorage(localStorage, {
  //   hash: function hash(key: any): any {
  //     key = CryptoJS.SHA256(key, SECRET_KEY);
  //     return key.toString();
  //   },
  //   // Encrypt the localstorage data
  //   encrypt: function encrypt(data: any) {
  //     data = CryptoJS.AES.encrypt(data, SECRET_KEY);
  //     data = data.toString();
  //     return data;
  //   },
  //   // Decrypt the encrypted data
  //   decrypt: function decrypt(data: any) {
  //     data = CryptoJS.AES.decrypt(data, SECRET_KEY);
  //     data = data.toString(CryptoJS.enc.Utf8);
  //     return data;
  //   }
  // });

  setAuthToken(token: string) {
    this.setJsonValue(this.AUTH_TOKEN, token);
    // localStorage.setItem(this.AUTH_TOKEN, token);
    this.authToken = token;
  }

  getAuthToken(): String {
    if (this.authToken == undefined || this.authToken == null) {
      this.authToken = this.getJsonValue(this.AUTH_TOKEN);
      // this.authToken = localStorage.getItem(this.AUTH_TOKEN);
    }
    return this.authToken;
  }

  hasAuthToken(): boolean {
    if (localStorage.getItem('refreshFlag') == 'true') {
      if (this.authToken == undefined || this.authToken == null) {
        this.authToken = this.getJsonValue(this.AUTH_TOKEN);
        // this.authToken = localStorage.getItem(this.AUTH_TOKEN);
      }
      return this.authToken !== undefined && this.authToken !== null && this.authToken.length > 0 ? true : false;
    } else {
      if (this.authToken == undefined || this.authToken == null) {
        this.authToken = this.getJsonValue('this.AUTH_TOKEN');
        // this.authToken = localStorage.getItem(this.AUTH_TOKEN);
      }
      return this.authToken !== undefined && this.authToken !== null && this.authToken.length > 0 ? true : false;
    }

  }

  deleteToken(): void {
    this.authToken = undefined;
    localStorage.removeItem(this.AUTH_TOKEN);
  }

  deleteAll(): void {
    this.authToken = undefined;
    localStorage.clear();
  }

  setRoleId(roleid: string) {
    localStorage.setItem(this.ROLE_ID, roleid);
    this.roleid = roleid;
  }

  getRoleId(): string {
    if (this.roleid == undefined || this.roleid == null) {
      this.roleid = localStorage.getItem(this.ROLE_ID);
    }
    return this.roleid;
  }

  setRoleName(roleName: string) {
    localStorage.setItem(this.ROLE_Name, roleName);
    this.roleName = roleName;
  }
  getRoleName(): string {
    if (this.roleName == undefined || this.roleName == null) {
      this.roleName = localStorage.getItem(this.ROLE_Name);
    }
    return this.roleName;
  }

  setPermissionObject(permissionobject: any) {
    localStorage.setItem(this.PERMISSION_OBJECT, permissionobject);
    this.permissionobject = permissionobject;
    this.permissionSubj.next(permissionobject);
  }

  getPermissionSub() {
    return this.permissionSubj;
  }

  getPermissionObject(): any {
    if (this.permissionobject == undefined || this.permissionobject == null) {
      this.permissionobject = localStorage.getItem(this.PERMISSION_OBJECT);
    }
    this.permissionSubj.next(this.permissionobject);
    return this.permissionobject;
  }

  setSiteId(siteid: string) {
    localStorage.setItem(this.SITE_ID, siteid);
    this.siteid = siteid;
  }

  getSiteId(): string {
    if (this.siteid == undefined || this.siteid == null) {
      this.siteid = localStorage.getItem(this.SITE_ID);
    }
    return this.siteid;
  }

  setcompanyWeeklyOff(companyweeklyoff: string) {
    localStorage.setItem(this.COMPANY_WeeklyOff, companyweeklyoff);
    this.companyweeklyoff = companyweeklyoff;
  }

  getCompanyWeeklyOff(): string {
    if (this.companyweeklyoff == undefined || this.companyweeklyoff == null) {
      this.companyweeklyoff = localStorage.getItem(this.COMPANY_WeeklyOff);
    }
    return this.companyweeklyoff;
  }

  setCompanyId(companyid: string) {
    localStorage.setItem(this.COMPANY_ID, companyid);
    this.companyid = companyid;
  }

  getCompanyId(): string {
    if (this.companyid == undefined || this.companyid == null) {
      this.companyid = localStorage.getItem(this.COMPANY_ID);
    }
    return this.companyid;
  }



  setDatabaseName(databaseName: string) {
    localStorage.setItem(this.DATABASE_NAME, databaseName);
    this.databasename = databaseName;
  }

  getDatabaseName(): string {
    if (this.databasename == undefined || this.databasename == null) {
      this.databasename = localStorage.getItem(this.DATABASE_NAME);
    }
    return this.databasename;
  }

  setLocationId(locationid: string) {
    localStorage.setItem(this.LOCATION_ID, locationid);
    this.locationid = locationid;
  }

  getLocationId(): string {
    if (this.locationid == undefined || this.locationid == null) {
      this.locationid = localStorage.getItem(this.LOCATION_ID);
    }
    return this.locationid;
  }

  setUserId(userid: string) {
    localStorage.setItem(this.USER_ID, userid);
    this.userid = userid;
  }

  getUserId(): string {
    if (this.userid == undefined || this.userid == null) {
      this.userid = localStorage.getItem(this.USER_ID);
    }
    return this.userid;
  }
  // setJsonValue(key: string, value: any) {
  //   this.secureStorage.setItem(key, value);
  // }

  // // Get the json value from local storage
  // getJsonValue(key: string) {
  //   return this.secureStorage.getItem(key);
  // }


  setCurrentPageNumber(currentpagenumber: string) {
    localStorage.setItem(this.CURRENT_PAGE_NUMBER, currentpagenumber);
    this.currentpagenumber = currentpagenumber;
  }

  getCurrentPageNumber(): string {
    if (this.currentpagenumber == undefined || this.currentpagenumber == null) {
      this.currentpagenumber = localStorage.getItem(this.CURRENT_PAGE_NUMBER);
    }
    return this.currentpagenumber;
  }

  setRequestParamId(requestId) {
    localStorage.setItem(this.REQUEST_ID, requestId);
    this.requestid = requestId;
  }

  getRequestParamId(): string {
    if (this.requestid == undefined || this.requestid == null) {
      this.requestid = localStorage.getItem(this.REQUEST_ID);
    }
    return this.requestid;
  }

  setMapType(mapType: string) {
    localStorage.setItem(this.MAP_TYPE, mapType);
    this.maptype = mapType;
  }

  getMapType(): string {
    if (this.maptype == undefined || this.maptype == null) {
      this.maptype = localStorage.getItem(this.MAP_TYPE);
    }
    return this.maptype;
  }


  setTripGenerationData(tripgendata: string) {
    localStorage.setItem(this.TRIP_GEN_DATA, tripgendata);
    this.tripgendata = tripgendata;
  }

  getTripGenerationData(): string {
    if (this.tripgendata == undefined || this.tripgendata == null) {
      this.tripgendata = localStorage.getItem(this.TRIP_GEN_DATA);
    }
    return this.tripgendata;
  }


  setRouteName(routeName: string) {
    localStorage.setItem(this._mRouteName, routeName);
    this.routeName = routeName;
  }

  getRouteName(): string {
    if (this.routeName == undefined || this.routeName == null) {
      this.routeName = localStorage.getItem(this._mRouteName);
    }
    return this.routeName;
  }

  setSubRouteName(subRouteName: string) {
    localStorage.setItem(this._mSubRouteName, subRouteName);
    this.subRouteName = subRouteName;
  }

  getSubRouteName(): string {
    if (this.subRouteName == undefined || this.subRouteName == null) {
      this.subRouteName = localStorage.getItem(this._mSubRouteName);
    }
    return this.subRouteName;
  }

  setUserObject(userobject: string) {
    let object = encodeURIComponent(JSON.stringify(userobject));
    this.setJsonValue(this.USER_OBJECT, object);
    this.userobject = object;
  }

  getUserObject(): string {
    let decryptObj = this.getJsonValue(this.USER_OBJECT);
    this.userobject = JSON.parse(decodeURIComponent(decryptObj));
    return this.userobject;
  }

  setUserEditId(usereditid: string) {
    localStorage.setItem(this.USER_EDIT_ID, usereditid);
    this.usereditid = usereditid;
  }

  getUserEditId(): string {
    if (this.usereditid == undefined || this.usereditid == null) {
      this.usereditid = localStorage.getItem(this.USER_EDIT_ID);
    }
    return this.usereditid;
  }

  // Set the json data to local storage
  setJsonValue(key: string, value: any) {
    this.secureStorage.setItem(key, value);
  }

  // Get the json value from local storage
  getJsonValue(key: string) {
    return this.secureStorage.getItem(key);
  }

  public secureStorage = new SecureStorage(localStorage, {
    hash: function hash(key: any): any {
      key = CryptoJS.SHA256(key, SECRET_KEY);
      return key.toString();
    },
    // Encrypt the localstorage data
    encrypt: function encrypt(data: any) {
      data = CryptoJS.AES.encrypt(data, SECRET_KEY);
      data = data.toString();
      return data;
    },
    // Decrypt the encrypted data
    decrypt: function decrypt(data: any) {
      data = CryptoJS.AES.decrypt(data, SECRET_KEY);
      data = data.toString(CryptoJS.enc.Utf8);
      return data;
    }
  });

}