import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from "rxjs";
import { AppConstants } from 'src/app/constant/appconstants';

@Injectable({ providedIn: 'root' })
export class DbService {

  constructor(private http: HttpClient) { }

  private headers = new HttpHeaders().set('Content-Type', 'application/json');
  private qrheaders = new Headers({'Content-Type': 'application/pdf', 'Accept': 'application/pdf'});

  /*private headerswithset = {
    headers: new HttpHeaders()
      .set('Content-Type', 'application/json')
  };

    private headerswithresponse = {
        headers: new HttpHeaders()
          .set('Content-Type', 'application/json'), observe: 'response'
      };*/


  doGet(url, successCallBack, errorCallBack) {

    this.http.get(url, { headers: this.headers }).subscribe(response => {
      successCallBack(response);
    }, error => {
      errorCallBack(error);
    });
  }

  //Get with response  
  doGetWithResponse(url, successCallBack, errorCallBack) {
    this.http.get(url, { headers: this.headers, observe: 'response' }).subscribe(response => {
      successCallBack(response);
    }, error => {
      errorCallBack(error);
    });
  }

  //Get with params
  doGetWithParams(url, params, successCallBack, errorCallBack) {
    this.http.get(url, { params: params, headers: this.headers }).subscribe(response => {
      successCallBack(response);
    }, error => {
      errorCallBack(error);
    });
  }

  //Post with params
  doPostWithParams(url, params, successCallBack, errorCallBack) {
    this.http.post(url, { params: params, headers: this.headers }).subscribe(response => {
      successCallBack(response);
    }, error => {
      errorCallBack(error);
    });
  }

  //Get with params and response
  doGetWithParamsAndResponse(url, params, successCallBack, errorCallBack) {
    this.http.get(url, { params: params, headers: this.headers, observe: 'response' }).subscribe(response => {
      successCallBack(response);
    }, error => {
      errorCallBack(error);
    });
  }
  
  
   // SSO Login API
  doGetSsoLogin(url, params, clientCode, successCallBack, errorCallBack) {
    this.http.get(url, { params: params, headers: { 'Content-Type': 'application/json', 'Client_Code': clientCode }, observe: 'response' }).subscribe(response => {
      successCallBack(response);
    }, error => {
      errorCallBack(error);
    });
  }

  //Get observable
  doGetObservable(url): Observable<any> {
    return this.http.get(url, { headers: this.headers });
  }

  //Post
  doPost(url, data, successCallBack, errorCallBack) {
    this.http.post(url, data, { headers: this.headers }).subscribe(response => {
      successCallBack(response);
    }, error => {
      errorCallBack(error);
    });

  }

  //Post with response
  doPostWithResponse(url, data, successCallBack, errorCallBack) {
    this.http.post(url, JSON.stringify(data), { headers: this.headers, observe: 'response' }).subscribe(response => {
      successCallBack(response);
    }, error => {
      errorCallBack(error);
    });

  }

  doPostWithEmailResponse(url, data, successCallBack, errorCallBack) {
    let headers = new HttpHeaders().set('Content-Type', 'application/json').append('Authentication-Type', AppConstants.Authentication_Type_Email);
    this.http.post(url, JSON.stringify(data), { headers: headers, observe: 'response' }).subscribe(response => {
      successCallBack(response);
    }, error => {
      errorCallBack(error);
    });

  }

  doPostWithVisitor(url, data, clientCode, successCallBack, errorCallBack) {
    this.http.post(url, JSON.stringify(data), { headers: { 'Content-Type': 'application/json', 'Client_Code': clientCode }, observe: 'response' }).subscribe(response => {
      successCallBack(response);
    }, error => {
      errorCallBack(error);
    });
  }

  doGetWithVisitor(url, params, clientCode, successCallBack, errorCallBack) {
    this.http.get(url, { params: params, headers: { 'Content-Type': 'application/json', 'Client_Code': clientCode }, observe: 'response' }).subscribe(response => {
      successCallBack(response);
    }, error => {
      errorCallBack(error);
    });
  }

  doPostFileVisitor(url, data, clientCode, successCallBack, errorCallBack) {
    this.http.post(url, data, { headers: { 'Content-Type': 'application/json', 'Client_Code': clientCode }, observe: 'response' }).subscribe(response => {
      successCallBack(response);
    }, error => {
      errorCallBack(error);
    });
  }

  doPostWithParamsAndResponse(url, data, params, successCallBack, errorCallBack) {
    this.http.post(url, JSON.stringify(data), { params: params, headers: this.headers, observe: 'response' }).subscribe(response => {
      successCallBack(response);
    }, error => {
      errorCallBack(error);
    });

  }

  doPostWithParamsAndResponseWithoudBody(url, params, successCallBack, errorCallBack) {
    this.http.post(url, { params: params, headers: this.headers, observe: 'response' }).subscribe(response => {
      successCallBack(response);
    }, error => {
      errorCallBack(error);
    });

  }

  //Post file
  doPostFile(url, data, successCallBack, errorCallBack) {
    this.http.post(url, data).subscribe(response => {
      successCallBack(response);
    }, error => {
      errorCallBack(error);
    });

  }

  //Put
  doPut(url, data, successCallBack, errorCallBack) {
    this.http.put(url, JSON.stringify(data), { headers: this.headers }).subscribe(response => {
      successCallBack(response);
    }, error => {
      errorCallBack(error);
    });

  }

  //Put with response
  doPutWithResponse(url, data, successCallBack, errorCallBack) {
    this.http.put(url, JSON.stringify(data), { headers: this.headers, observe: 'response' }).subscribe(response => {
      successCallBack(response);
    }, error => {
      errorCallBack(error);
    });

  }

  //Put with params
  doPutWithParams(url, data, params, successCallBack, errorCallBack) {
    this.http.put(url, JSON.stringify(data), { params: params, headers: this.headers }).subscribe(response => {
      successCallBack(response);
    }, error => {
      errorCallBack(error);
    });
  }

  //Put with params and response
  doPutWithParamsAndResponse(url, data, params, successCallBack, errorCallBack) {
    this.http.put(url, JSON.stringify(data), { params: params, headers: this.headers, observe: 'response' }).subscribe(response => {
      successCallBack(response);
    }, error => {
      errorCallBack(error);
    });
  }

  //Delete with response
  doDelete(url, successCallBack, errorCallBack) {
    this.http.delete(url, { headers: this.headers, observe: 'response' }).subscribe(response => {
      successCallBack(response);
    }, error => {
      errorCallBack(error);
    });

  }

  //Delete with response
  doDeleteWithParamsAndResponse(url, params, successCallBack, errorCallBack) {
    this.http.delete(url, { params: params, headers: this.headers, observe: 'response' }).subscribe(response => {
      successCallBack(response);
    }, error => {
      errorCallBack(error);
    });

  }

  doPostForJobs(url, successCallBack, errorCallBack) {
    this.http.post(url, { headers: this.headers }).subscribe(response => {
      successCallBack(response);
    }, error => {
      errorCallBack(error);
    });

  }

  //add header - "Client_Code"
  doPostWithEmailHead(url, data, cleintCode, successCallBack, errorCallBack) {
    this.http.post(url, JSON.stringify(data), { headers: { 'Content-Type': 'application/json', 'Client_Code': cleintCode }, observe: 'response' }).subscribe(response => {
      successCallBack(response);
    }, error => {
      errorCallBack(error);
    });

  }

  //used for get-login-otp - removed JSON.stringify(data)
  doPostWithEmailHead2(url, data, cleintCode, successCallBack, errorCallBack) {
    this.http.post(url, data, { headers: { 'Content-Type': 'application/json', 'Client_Code': cleintCode }, observe: 'response' }).subscribe(response => {
      successCallBack(response);
    }, error => {
      errorCallBack(error);
    });

  }

  doGetWithEmailHead(url, cleintCode, successCallBack, errorCallBack) {
    this.http.get(url, { headers: { 'Content-Type': 'application/json', 'Client_Code': cleintCode }, observe: 'response' }).subscribe(response => {
      successCallBack(response);
    }, error => {
      errorCallBack(error);
    });
  }

  doPutWithEmailHead(url, cleintCode, successCallBack, errorCallBack) {
    this.http.put(url, " ", { headers: { 'Content-Type': 'application/json', 'Client_Code': cleintCode }, observe: 'response' }).subscribe(response => {
      successCallBack(response);
    }, error => {
      errorCallBack(error);
    });
  }

  doPutWithEmailPwdHead(url, data, cleintCode, successCallBack, errorCallBack) {
    this.http.put(url, JSON.stringify(data), { headers: { 'Content-Type': 'application/json', 'Client_Code': cleintCode }, observe: 'response' }).subscribe(response => {
      successCallBack(response);
    }, error => {
      errorCallBack(error);
    });

  }
  doGetWithBase64(url, params, successCallBack, errorCallBack) {
    this.http.get(url, { params: params, headers: this.headers, observe: 'response',responseType :'text'}).subscribe(response => {
      successCallBack(response);
    }, error => {
      errorCallBack(error);
    });
  }

  doGetWithPdf(url, params, successCallBack, errorCallBack) {
    this.http.get(url, { params: params, headers: {'Content-Type': 'application/pdf', 'Accept': 'application/pdf'}, responseType: 'blob' as 'json'}).subscribe(response => {
      successCallBack(response);
    }, error => {
      errorCallBack(error);
    });
  }

}
