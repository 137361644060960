/**
 * Its a collection of all api urls
 * Define methods to set http method api urls
 */
import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';

@Injectable({ providedIn: 'root' })
export class RequestUtils {
    public static BASE_URL_REPORT = 'http://180.179.227.159/api/atom-billing/dashboard/';
    public static BASE_URL: string = environment.baseUrl;
    public static BASE_URL_BILLING: string = environment.baseUrlBilling;
    public static ROUTING_SERVICE_URL: string = environment.baseUrl;
    // static API_BASE_URL: string;
    //  public static BASE_URL_BILLING_UAT: string = environment.baseUrlBillingUat;
    // public static ROUTING_SERVICE_URL: string = "http://localhost:8071/atom/api/"
    // public static ROUTING_SERVICE_URL: string = "http://202.21.35.83:8089/api/routing-service/routing/api/"
    // public static TRACKING_URL: string = "http://202.21.35.83:8075/track/api/track/gettriptrace";
    // public static TRACKING_URL = 'http://202.21.35.83:8075/track/api/track/';
    // public static TRACKING_URL: string ="http://localhost:8075/track/api/track/";

    getBaseUrl(): string {
        return RequestUtils.BASE_URL;
    }

    reportCommon() {
        return {
            getSites: 'getsites',
            getCities: 'getcities',
            getVendors: 'getvendors',
            getVehicleType: 'getvehicletypes'
        };
    }

    reportCosttrend() {
        return {
            getDayWise: 'dailycosttrend',
            getMonthWise: 'monthlycosttrend',
            getYearWise: 'yearlycosttrend'
        };
    }

    getLoginUrl() {
        return RequestUtils.BASE_URL + 'login';
    }

    addTempUserUrl() {
        return RequestUtils.BASE_URL + this.tempUserController().addTempUser;
    }

    getLocationListUrl() {
        return RequestUtils.BASE_URL + this.tempUserController().getlocationlist;
    }

    getSitesListByLocationIdUrl() {
        return RequestUtils.BASE_URL + this.tempUserController().getsiteslist;
    }

    getAreasListByLocationIdUrl() {
        return RequestUtils.BASE_URL + this.tempUserController().getAreaslist;
    }

    getLandmarksListByAreaUrl() {
        return RequestUtils.BASE_URL + this.tempUserController().getLandmarkslist;
    }

    getTempUserUrl() {
        return RequestUtils.BASE_URL + this.tempUserController().getTempUser;
    }

    getUserByIdUrl() {
        return RequestUtils.BASE_URL + this.tempUserController().getUserById;
    }

    signUpUrl() {
        return RequestUtils.BASE_URL + this.userController().signUp;
    }

    SiteUrl() {
        return RequestUtils.BASE_URL + this.site().getAll;
    }

    tempUserUrl() {
        return RequestUtils.BASE_URL + this.userController().getTempUser;
    }

    addUserUrl() {
        return RequestUtils.BASE_URL + this.userController().addUser;
    }

    userRegistrationUrl() {
        return RequestUtils.BASE_URL + this.tempUserController().userRegistration;
    }

    updateUserUrl() {
        return RequestUtils.BASE_URL + this.tempUserController().updateUser;
    }

    forgotPasswordUrl() {
        return RequestUtils.BASE_URL + this.tempUserController().forgotPassword;
    }

    changePasswordUrl() {
        return RequestUtils.BASE_URL + this.userController().changePassword;
    }

    vendorMaster() {
        return {
            getVendorMasterDetailsRefersh: 'vendormaster/get',
            getAllVendorDocs: 'vendormaster/getvmdocs',
            getAllVendorMasterForDropDown: 'vendormaster/getallvendormastersfordropdown',
            getMastersList: 'vendormaster/getallvendors',
            addMaster: 'vendormaster',
            updateMaster: 'vendormaster',
            deleteMaster: 'vendormaster?id=',
            auditMaster: 'vendormaster/audit?id=',
            enabledisablevendormaster: 'vendormaster/enabledisablevendormaster',
            getAllVendorDropDownBasedOnSite: 'vendormaster/getallvendorfordropdown',
            getVendorBySites: 'vendormaster/getallvendormasterbysites'
        };
    }

    vendor() {
        return {
            getVendors: 'vendor',
            getAllVendorsDropdown: 'vendor/getvendorsdropdown',
            getVendorsListPageable: 'vendor/getallvendorusers',
            getVendorList: 'vendor/getvendorlist',
            addVendor: 'vendor',
            updateVendor: 'vendor',
            deleteVendor: 'vendor?id=',
            auditVendor: 'vendor/audit?id=',
            enabledisablevendor: 'vendor/enabledisablevendor',
            getVendorByUserId: 'vendor/getvendorbyuserid'
        };
    }

    site() {
        return {
            getAll: "site",
            getSiteSearch: "site/getsitesbyname",
            getSitesByLocationId: "site/getsitesbylocation",
            getAllSitesByLocationId: "site/getallsitesbylocation",
            get: "site/getsitesbyid",
            add: "site",
            update: "site",
            delete: "site?id=",
            mapSiteShift: "site/updatesiteshift",
            updateBoundary: "site/updateboundary",
            deleteBoundary: "site/deleteboundary",
            getBoundaryAudit: "site/boundaryaudit",
            getSiteListForDropDown: "site/getsitelist",
            getSiteShiftTimes: "site/getsiteshifttimesbylocationid",
            getActiveSitesbylocationid: "site/getactivesitesbylocationid",
            getActiveSites: "site/getsitelist",
            getActiveSitesbyUser: 'site/getsitesbyuserid',
            getActiveSitesByLocation: "site/getactivesitesbylocation",
        };
    }

    vehicleType() {
        return {
            getAll: 'vehicletype',
            add: 'vehicletype',
            update: 'vehicletype',
            delete: 'vehicletype?id=',
            audit: 'vehicletype/audit',
            enabledisablevehicletype: 'vehicletype/enabledisablevehicletype',
            getvehicletypeslist: 'vehicletype/getvehicletypeslist',
            getSitesNameByVehicleTypeId: 'vehicletype/getsitesnamebyvehicletype',
            deleteSiteVehicleTypeMappingByVehicleTypeId: 'vehicletype/deletesitevehicletypemmapingbyid',
        };
    }

    city() {
        return {
            getAll: 'location',
            delete: 'location?locationid=',
            add: 'location',
            update: 'location',
            getAllCities: 'location/locations',
            getCities: 'city'
        };
    }

    vehicle() {
        return {
            getAllVehicleDocs: 'vehicle/getvehiclebyidfordocs',
            getById: 'vehicle/getvehiclebyid',
            getAll: 'vehicle',
            getAllByVendorId: 'vehicle/getallvehicles',
            add: 'vehicle',
            update: 'vehicle',
            updatevehiclesite: 'vehicle/updatevehiclesite',
            updatevehicledriver: 'vehicle/updatevehicledriver',
            getVehicleExpiryCount: 'vehicle/getvehiclesexprirycount',
            getVehicleExpiryList: 'vehicle/getvehiclesexprirylist',
            enabledisablevehicle: 'vehicle/enabledisablevehicle',
            getuploaderror: 'vehicle/getuploaderror',
            getVehicleBySiteIdVendorIdVehicleTypeId: 'vehicle/getlistbysiteidvendoridandvehicletypeid',
            getvehicleexprirycounts: 'vehicle/getvehicleexprirycounts',
            getvehiclesexprirydetails: 'vehicle/getvehiclesexprirydetails',
            getvehicleexpirybyvendormasteridandsiteidandtypeandstat: 'vehicle/getvehicleexpirybyvendormasteridandsiteisandtypeandstat',
            getaudit: 'vehicle/audit?id=',
            getActiveVehiclesCountByVendorMasterIdAndSiteId: 'vehicle/getactivevehiclescountbyvendormasteridandsiteid',
            getVehicleCertificateExpiryDateIsNull: 'vehicle/getvehiclecertificateExpirydateisnull',
            getVehiclesListByCertificateExpiryDateIdNull: 'vehicle/getvehiclelistbycertificateexpirydateisnull',
            getVehicleDocumentsStatusCounts: 'vehicle/getVehicleDocumentsStatusCounts',
            getVehicleListByVendorMasterId: 'vehicle/getvehiclebyvendormasterid',
            getvehiclelistbyvendormasterid: 'vehicle/getvehiclelistbyvendormasterid',
            getSiteslistbyvehicleIdAndVendormasterId: 'vehicle/getsitevehiclebyvehicleidandvendorid',
            getAllVehiclesBySiteIdAndVendorMasterId: 'vehicle/getallvehiclesbysiteidandvendormasterid'
        };
    }

    driver() {
        return {
            updateHygieneDetails: 'hygieneinspectiondays/addVehicleHygiene',
            getAllDriverDocs: 'driver/getdriverbyid',
            getByVendorMaster: "driver",
            getByVendor: "driver/getdriverslistbyvendor",
            updatedrivervehicle: "vehicle/updatedrivervehicle",
            add: "driver",
            update: "driver",
            delete: "driver?id=",
            enabledisabledriver: "driver/enabledisabledriver",
            getdriverbyid: "driver/getdriverbyid?id=",
            getdriverbyVehicleId: "driver/getdriversbyvehicleid?vehicleId=",
            getdriveraudit: "driver/audit?id=",
            getuploaderror: "driver/getuploaderror",
            getDriversExpiryCounts: "driver/getdriversexprirycount",
            getDriverExpiryDetails: "driver/getdriversexprirydetails",
            getdriverexpirybyvendormasteridandtypeandstat: "driver/getdriverexpirybyvendormasteridandtypeandstat",
            getActiveDriversCountByVendorMasterId: "driver/getactivedriverscountbyvendormasterid",
            getDriversCertificateDateIsNull: "driver/getdrivercertificateExpiryateisnull",
            getDriversListByCertificateDateIsNull: "driver/getdriverslistbycertificateExpirydateisnull",
            getDriverListByVendorMasterId: "driver/getdriverslistbyvendormasterid",
            getDriverDocumentsStatusCounts: "driver/getDriverDocumentsStatusCounts",
            getDriverListByVehicleId: "driver/getdriversbyvehicleid",
            getDriversByVendorMasterId: "driver/getdriversbyvendormasterid",
            getAllDriverWithTemp: "driver/getalldriverwithtemp",
            updateDriverTemp: "driver/updatedrivertemp",
            getDriversList: "driver/getdriverregistrations",
            unregisterDriver: "driver/unregisterdriver",
        }
    }

    qrController() {
        return {
            generateQrCode: "qrcode/generateQrCode"
        }
    }

    shiftTimeController() {
        return {
            getAll: 'shift',
            add: 'shift',
            update: 'shift',
            delete: 'shift?shifttimeid=',
            getAllBySiteId: 'shift/getshiftbysiteid',
            getLeaveId: 'employeeleave/getleaveshifts',
            getHolidayId: 'employeeleave/getholidayshifts',
            getWeeklyOffId: 'shift/getweeklyoffshifts',
            getAllBySiteIdGen: 'shift/getbysiteshifttypegeneral',
            getAllSiteByTypes: 'getshiftbytypes',
            getShiftsBySiteAndType: 'shift/getbysiteshifttype',
            enabledisableshifttime: 'shift/enabledisableshifttime',
            getShiftTimesBySiteIdShiftTypeAndLogType: 'shift/getshiftbysiteidandshifttypeandlogtype',
            getShiftTimesListBySiteId: 'search/getshifttimesListbysiteid',
            getCancelId: 'employeeleave/getcancelshifts'
        };
    }

    bookingController() {
        return {
            getAll: 'booking',
            get: 'bookingsetup',
            getById: 'bookingsetup/get?bookingid=',
            getByUsers: 'booking/getbookingsbyusers',
            add: 'bookingsetup',
            update: 'bookingsetup',
            audit: 'bookingsetup/audit?id=',
            saveBooking: 'booking/submitbookings',
            enabledisable: 'bookingsetup/enabledisablebookingsetup',
            downloadBookings: 'booking/download',
            uploadBookings: 'booking/upload',
            bookingType: 'specialbooking/bookingtype',
            leaveDetails: 'employeeleave/getemployeeleavedetails',
            holidayDetails: 'employeedevice/getholidaylist',
            getBookingSetupBySiteId: 'bookingsetup/getBookingSetupById',
            addBookingViolationSetup: 'bookingviolationsetup/addBookingViolationSetup',
            updateBookingViolationSetup: 'bookingviolationsetup/updateBookingViolationSetup',
            getAllBookingViolationSetups: 'bookingviolationsetup/getAllBookingViolationSetups',
            enabledisableBookingViolation: 'bookingviolationsetup/enabledisableBookingViolation'
        };
    }

    adhocController() {
        return {
            getAllEscortRateSetupView: 'escortratesetup/getallescortratesetupview',
            checkBookings: 'adhocbooking/getbookingsforempforvalid',
            getAdhocsForEmp: 'adhocbooking/getadhocsforemp',
            cancelAdhoc: 'adhocbooking/cancelbooking',
            approveAdhoc: 'adhocbooking/approvebooking',
            isCutoffValid: 'adhocbooking/validatecutoff',
            getAdhocTypesBySiteIdAndUserId: 'adhocsetup/getadhocbysiteidanduserid',
            getAllAdhocTypesForDropdown: 'adhoctypes',
            getShiftTimeForEmp: 'adhocbooking/getshifttimeforemp',
            getAllPickDropBySiteIdAdhocTypes: "adhocsetup/getpickdropbysiteidandadhoctypes",
            getAllAdhocTypesBySiteId: 'adhocsetup/getadhocbysiteid',
            getAll: 'adhocsetup/get?adhocsetupid=',
            add: 'adhocsetup',
            getAllApprovers: 'adhocapprover',
            getAllTypes: 'adhoctypes',
            delete: 'adhocbooking?id=',
            getAdhocByUsers: 'adhocbooking/getadhocbookings',
            getAllAdhocBookigView: 'adhocbooking/getalladhocbookingsview',
            getAllAdhocPendingsBySiteIdAndSpocId: 'adhocbooking/getAllAdhocPendingsBySiteIdAndSpocId',
            update: 'adhocbooking',
            enabledisable: 'adhocsetup/enabledisable',
            getAdhocSetupBySiteId: 'adhocsetup/getadhocsetupbysiteid',
            getAdhocSetupBySiteIdAndPickUpDrop: 'adhocsetup/getadhocsetupbysiteidandpickupdrop',
            getAdhocSetupAudit: 'adhocsetup/audit?id=',
            getLateNightSetupAudit: 'latenightsetup/audit?id=',
            lateNightSetup: 'latenightsetup',
            empbookingaccess: 'empbookingaccess', // need to create new controller for this..
            lateNightShift: 'shift/latenightshift', // need to create new controller for this..
            latenightcab: 'latenightcab',
            cancelbooking: 'latenightcab/cancelbooking'
        };
    }

    companyController() {
        return {
            listOfcompanies: 'company',
            getAll: 'company/getcompanies',
            add: 'company',
            update: 'company',
            delete: 'company?companyid=',
            enableDisable: 'company/enabledisable',
            getDatabaseName: 'masterconfig/getclientname?companyid='
        };
    }

    getPrivilegeUrl() {
        return RequestUtils.BASE_URL + 'privilege';
    }

    getAddLocationUrl() {
        return RequestUtils.BASE_URL + 'location';
    }

    getSiteUrl() {
        return RequestUtils.BASE_URL + 'site';
    }

    getDeleteSiteUrl() {
        return RequestUtils.BASE_URL + 'site?siteid=';
    }

    getCompanyUrl() {
        return RequestUtils.BASE_URL + 'company';
    }

    getDeleteCompanyUrl() {
        return RequestUtils.BASE_URL + 'company?companyid=';
    }

    userController() {
        return {
            getSubscribedEmpUnderSpoc: 'subscription/getsubscriptionlistforspoc',
            addUser: 'userinfo',
            updateUSer: 'userinfo',
            updateuser: 'user/updateuser',
            deleteUser: 'userinfo?userid=',
            getEmpUnderSpoc: 'user/getuserlistbyspoc',
            getUser: 'user',
            audit: 'user/audit?id=',
            getAllUser: 'user/getallusers',
            getAllCovidUser: 'covid/getallcovidusers',
            getExceptionAllUser: 'user/getallexceptionusers',
            searchUserEmployee: 'user/searchuseremployee',
            searchUserInfo: 'user/search',
            searchSpoc: 'user/search?roleid=3&searchkey=',
            searchUser: 'user/usersearch?searchkey=',
            getAllUserDocs: 'user/getbyuserinfoid',
            getUserInfoDetails: 'user/getbyuserinfoid?id=',
            getUserInfoDetailsById: 'user/getbyuserinfobyid?id=',
            getCurrentUser: 'user/currentuserinfo',
            getAllUserPending: 'user/tempusers',
            getRole: 'role',
            getAllRole: 'role/getroles',
            getRolePermissions: 'role/getrolepermissions?roleId=',
            addRole: 'role',
            getRoleById: 'get?roleId=',
            deleteRole: 'role?id=',
            updateRole: 'role',
            resetPassword: 'userinfo/resetpassword?emailid=',
            changePassword: 'user/changepassword',
            signUp: 'tempuser',
            getTempUser: 'tempuser?id=',
            getuploaderror: 'user/getuploaderror',
            enabledisable: 'userinfo/enabledisable',
            enabledisablerole: 'role/enabledisable',
            searchSpocUsers: 'user/searchspocusers',
            searchReportingOfficers: 'user/searchreportingofficer',
            reportingOfficersList: 'user/reportingofficerlist',
            spocUsersList: 'user/spocuserslist',
            searchUserByFirstName: 'user/searchuserbyfirstname',
            getuserbyfirstname: 'user/getuserbyfirstname',
            getuserlandmarks: 'user/getuserlandmarks',
            getexceptionemployeesearch: 'user/getexceptionemployeesearch',
            getnoshowcount: 'tripdetails/noshowemployeecount',
            getUserEmployee: 'user/getuseremployee',
            getLoginOtp: 'newuser/get-login-otp',
        };
    }


    covidUserController() {
        return {
            getAllCovidUsers: 'covid',
            addUser: 'covid',
            updateUser: 'covid',
            getEmployeeStationReport: 'covid/employeestationreport',
            getCapitalCitiesCovidCasesInfo: 'covid/getcapitalcitiescovidcases',
            getDistrictLevelCovidCases: 'covid/getdistrictlevelcovidcases',
            getUsersByDistrictName: 'covid/getusersbydistrictname',
            get_state_district_wise: 'covid/get_state_district_wise',
            getStateAndDistrictWiseForDashboard: 'covid/getstateanddistrictwisefordashboard',
            getNonGeocodeUsersReport: 'covid/getnongeocodesers',
            getGeoCodedUsers: 'covid/getgeocodedusers',
            getTopFiveStates: 'covid/gettopfivestates',
            getOverAllEmpData: 'covid/getoverallempdata',
            getTopFiveDistricts: 'covid/gettopfivedistricts',
            getTempDashboard: 'covid/gettempdashboard',
            getNumberOfGeoCoddedEmployee: 'covid/getnumberofgeocoddedemployee',
            getEmployeeTemperatureCount: 'covid/getemployeetemperaturecount',
            getDistrictWiseEmpDetails: 'covid/getdistrictwisedetails',
            getStateWiseDetails: 'covid/getstatewisedetails',
            sendReminderNonGeocodeUsers: 'covid/sendremindernongeocodeusers',
            getEmployeeCatagoryCount: 'covid/getemployeecatagorycount',
            getEmployeeDistrictCount: 'covid/getemployeedistrictcount',
            getContaintmentDetails: 'covid/getcontaintmentdetails',
            getDeclarationDetails: 'covid/getdeclarationdetails',
            getCovidSourceInfo: 'covid/getcovidsourceinfo',
            getAllTwitters: 'covid/getalltwitterdetails',
            addcovidzone: 'covid/addcovidzone',
            getcovidzones: 'covid/getcovidzones',
            addCovidSourceInfo: 'covid/addcovidsourceinfo',
            addTwitterInfo: 'covid/addtwitterinfo',
            addwarddata: 'covid/addwarddata',
            getWardNameAndColorCodeByStateAndDistrict: 'covid/getwardnameandcolorcodebystateanddistrict',
            deletecovidzonebyid: 'covid/deletecovidzonebyid',
            addHygeneChecklist: 'covid/hygeneCheckList',
            getAllHygeneData: 'covid/getAllHygene',
            updateHygene: 'covid/updateHygene'
        };
    }

    getRoleUrl() {
        return RequestUtils.BASE_URL + 'role';
    }

    getDeleteRoleUrl() {
        return RequestUtils.BASE_URL + 'role?roleId=';
    }

    getMasterConfigUrl() {
        return RequestUtils.BASE_URL + 'masterconfig';
    }

    getDeleteMasterConfigUrl() {
        return RequestUtils.BASE_URL + 'masterconfig?masterconfigid=';
    }

    getDeleteLocationUrl() {
        return RequestUtils.BASE_URL + 'location/';
    }

    moduleController() {
        return {
            getAll: 'module',
            add: 'module',
            update: 'module',
            delete: 'module?moduleid=',
            audit: 'module/audit?id=',
            getModuleList: 'module/getmodules'
        };
    }

    subModuleController() {
        return {
            getAll: 'submodule',
            add: 'submodule',
            update: 'submodule',
            delete: 'submodule?subModuleid=',
            audit: 'submodule/audit?id='
        };
    }

    notificationController() {
        return {
            getAll: 'notification',
            add: 'notification',
            update: 'notification',
            delete: 'notification?id=',
            audit: 'notification/audit?id=',
            getNotificationList: 'notification/notificationslist',
            enableDisable: 'notification/enabledisable',
            getNotificationTemplateById: 'notification/getnotificationbyid?id='
        };
    }

    masterConfigController() {
        return {
            getAll: 'masterconfig',
            add: 'masterconfig',
            update: 'masterconfig',
            delete: 'masterconfig?masterconfigId=',
            getAllConfig: 'masterconfig/masterconfig',
            getMasterConfigsByCompanyId: 'masterconfig/getcompany',
            enableDisable: 'masterconfig/enabledisable'
        };
    }

    projectController() {
        return {
            getAll: 'project',
            add: 'project',
            update: 'project',
            delete: 'project?id=',
            audit: 'project/audit?id='
        };
    }

    payRollConfig() {
        return {
            getAll: 'payroll/getpayrollconfigdetails',
            add: 'payroll/payrollconfig',
            update: 'payroll/updatepayrollconfig',
            enableDisable: 'payroll/enabledisablepayrollconfig',
            delete: 'payroll/payrollconfig?id=',
            audit: 'audit/getpayrollconfigaudit?id=',
            transport: 'transport/getTransportType'
        };
    }

    leavedetails() {
        return {
            getAll: 'holiday/getholidaylistbysiteid',
            add: 'holiday/addholidays',
            enableDisable: 'holiday/enabledisableholiday',
            update: 'holiday/updateholidaydetails',
            uploadHolidays: 'holiday/upload'
        };
    }

    pwConfig() {
        return {
            getAll: 'customauth/getcustomauthdetails',
            add: 'customauth/addcustomauthdetails',
            update: 'customauth/updatecustomauthdetails',
            enableDisable: 'customauth/enabledisablecustomauthdetails',
            // delete: 'customauth/payrollconfig?id=',
            // audit: 'customauth/getpayrollconfigaudit?id=',
            // transport:'transport/getTransportType'
        };
    }

    reportController() {
        return {
            getSubscriptionReport: "report/subscriptionreport",
            getPayRollReport: "payroll/getpayrolldetails",
            getLeaveDetailsReport: "report/getleavedetails",
            getUnSubscriptionReport: "report/unsubscriptionreport",
            getSchedulingReport: "report/schedulingreport",
            getReSchedulingReport: "report/reschedulingreport",
            getAdhocBookingReport: "report/adhocbookingreport",
            getEscortReport: "report/getescortreports",
            getEscortListForDownloadReport: "report/getescortlistfordownloadreports",
            getSpecialBookingReport: "report/specialbookingreport",
            getPanicActionReport: "report/panicactionreport",
            getMedicalEmployeeReport: "report/getmedicalemployeereport",
            getMedicalEmployeeListForDownloadReport: "report/getmediaclemployeeslistfordownloadreport",
            getEmployeeAppUsersReport: "employeeAppUser/getbysiteid",
            getEmployeeAppUsersListForDownloadReport: "report/getemployeeappuserslistfordownloadreport",
            getEscortTripcostSummaryReport: "report/escorttripcostsummaryreport",
            getEscortBilling: "report/getescortbilling",
            getFullBillingReport: "report/getfullbillingreport",
            getTripPushBackReport: "report/trippushbackreport",
            getBillingApproval: "billing/displaytracktrip",
            getApprovetracktrips: "billing/approvetracktrips",
            updateapprovalstatus: "billing/updateapprovalstatus",
            getEvBillingReport: "billing/evbillingreport",
            getProjectWiseBillingReport: "report/projectwisebillingreport",
            getProjectWiseNoShowReport: "report/projectwisenoshowreport",
            getProjectWiseNoShowSummaryReport: "report/projectwisenoshowsummaryreport",
            getExceptionReport: "report/fullexceptionsreport",
            getTripSummaryReport: "report/gettripreport",
            getTripsReport: "report/getfulltripreport",
            getTripUsageSummaryReport: "report/tripusagesummaryreport",
            getTripUsageReport: "report/tripusagereport",
            getTripUsageTripDetailsReport: "report/tripdetailsbytripid",
            getPunchInOutReport: "report/emppunchinoutreport",
            getVehicleUsageReport: "report/vehicleusagereport",
            getVehicleOccupancyReport: "report/vehiclesoccupencyreport",
            getVehicleStatusReport: "report/vehiclestatusreport",
            getVehiclesInspectionReport: "report/vehiclesinspectionreport",
            getOtaOtdSummaryReport: "report/getotaotdsummary",
            getOtaOtdReport: "report/getotaotdtripdetails",
            getEmployeeAppReport: "report/getemployeeappreport",
            getGeoFenceReport: "report/getGeoFenceReport",
            getTripStatmentSummaryReport: "report/tripstatmentsummaryreport",
            getTripStatmentReport: "report/tripstatmentreport",
            getBillingBuckets: "billing/getbillingbuckets",
            getBillingBucketsDetails: "billing/getbillingbucketsdetails",
            getCCWTripCostSummary: "report/getCCWTripCostSummary",
            getVehilceBillSummaryReport: "report/getVehilceBillSummaryReport",
            getAutoStopReport: "report/getautostopreport",
            getMonthlyReport: "report/monthlyreport",
            getMISReport: "report/getmisreport",
            getLateNightReport: "report/getlatenightreport",
            getEscortCostAllocationReport: "report/costcenterewiseescorttripreport",
            getDistanceReport: "report/distanceReport",
            getTemperatureReport: "report/gettemperaturereport",
            getShiftwiseReport: "tripdetails/shiftwisereport",
            getEmployeeNoShowReport: "report/getemployeenoshowReport",
            getTripWiseEmployeeNoShowReport: "report/gettripwiseemployeenoshowReport",
            getDriverTempReport: "report/getdrivertempreport",
            getSanitizationreport: "report/getsanitizationreport",
            getTripsEmployeeDetails: "report/gettripsemployeedetails",
            getVehicleStatusDetailedData: "report/getvehiclestatusdetaileddata",
            getAdjustmentTripReport: "adjustmenttrip/getalladjustmenttripreport",
            getOutOfBoundaryEmployeeReport: "landmark/getemployeesoutsideboundary"
        }
    }

    processController() {
        return {
            getSpocProcess: 'project/getspocprojects',
            getAll: 'project/getprojects?searchkey=',
            add: 'process',
            update: 'process',
            delete: 'process?id=',
            audit: 'process/audit?id=',
            enableDisable: 'project/enabledisable',
            getProcessList: 'project/projectslist',
            getCostCenterList: 'project/costcenterslist'
        };
    }

    subscriptionSetupController() {
        return {
            getAll: 'subscriptionsetup',
            add: 'subscriptionsetup',
            update: 'subscriptionsetup',
            delete: 'subscriptionsetup?subscriptionid=',
            audit: 'subscriptionsetup/audit?id=',
            monthlyCharge: 'subscriptionsetup/monthlycharge',
            template: 'subscriptionsetup/site',
            enabledisablesubsetup: '/subscriptionsetup/enabledisablesubsetup'
        };
    }

    subscriptionController() {
        return {
            getSubscriptionListOfEmp: "booking/getsubscriptionlistforemp",
            getAllUnSubscriptionsForSpoc: "subscription/getunsubscriptionlistforspoc",
            getAll: 'subscription',
            getAllSuspensionSubscriptions: "subscription/getAllSuspensionSubscriptions",
            getBySiteId: 'subscription/getsubscriptionbysiteid',
            add: 'subscription',
            getEmpUnderSpoc1: 'user/getusersunderspoc',
            audit: 'subscription/audit?id=',
            addUserSubscription: 'subscription/addUserSubscription',
            suspendSubscription: 'subscription/suspendSubscription',
            revokeSubscription: 'subscription/revokeSubscription',
            update: 'subscription',
            subscribeUser: 'subscription/getsubscribeuser?id=',
            getUserInfoDetails: 'subscription/users?userinfoid=',
            delete: 'subscription/?subscriptionid=',
            // isSubscribed: 'subscription/issubscribed',
            isSubscribed: 'subscription/issubscribedd',
            unSubscribeUser: 'subscription/unSubscribeUser',
            getSubscriptionReqCount: 'subscription/getsubscriptionreqcount',
            upload: 'upload',
            getSubscriptionHistory: 'subscription/getsubscriptionhistory',
            getAllUnsubscriptions: 'subscription/unsubscriptions',
            getUnsubscriptionList: 'subscription/unsubscriptionlist',
            getSubscriptionList: 'subscription/subscriptionlist',
            updateUnSubscriptionList: 'subscription/updateunsubscriptiondate',
            downloadSubscription: 'subscription/downloadsubscription'
        };
    }

    divisionController() {
        return {
            getAll: 'division',
            addDivision: 'division',
            update: 'division',
            delete: 'division?id='
        };
    }

    ivrSetupController() {
        return {
            getAll: 'ivrsetup',
            addConfig: 'ivrsetup',
            updateConfig: 'ivrsetup',
            getIvrConfigByDescription: 'ivrsetup/getIvrConfigByDescription'
        };
    }

    jobSetupController() {
        return {
            getAll: 'jobsetup',
            addJobSetup: 'jobsetup',
            updateJobSetup: 'jobsetup',
            deleteJobSetup: 'jobsetup?id=',
            audit: 'scheduler/audit?id=',
            getAllJob: 'scheduler/jobs',
            pauseScheduleJob: 'scheduler/pause?jobName=',
            resumeScheduleJob: 'scheduler/resume?jobName=',
            deleteScheduleJob: 'scheduler/delete?jobName=',
            addScheduleJob: 'scheduler/schedule',
            editScheduleJob: 'scheduler/update',
        };
    }

    mapSetupController() {
        return {
            getAll: 'mapsetup',
            addMapSetup: 'mapsetup',
            updateMapSetup: 'mapsetup',
            audit: 'mapsetup/audit?id=',
            deleteMapSetup: 'mapsetup?id=',
            enableDisable: 'mapsetup/enabledisable'
        };
    }

    emailTypeController() {
        return {
            getAll: 'emailtype',
        };
    }

    emailTemplateSetupController() {
        return {
            getAll: 'emailtemplatesetup',
            addemailTemplateSetup: 'emailtemplatesetup',
            updateemailTemplateSetup: 'emailtemplatesetup',
            deleteemailTemplateSetup: 'emailtemplatesetup?id='
        };
    }

    landmarkController() {
        return {
            addLandmark: 'landmark',
            getAll: 'landmark',
            getLandmarkByAreaId: 'landmark/getbyareaid',
            getLandmarkByLocationId: 'landmark/getall',
            getLandmarkByCityId: 'landmark',
            deleteLandmarkByCity: 'landmark/removepointsunderlocation',
            getlandmarkbytype: 'landmark/landmarkbytype',
            landmarkCountbytype: 'landmark/landmarkcountbytype',
            deleteLandmark: 'landmark/removelandmark',
            getAvailablelandmarktype: 'landmark/gettype',
            getuploaderror: 'landmark/getuploaderror',
            getBillingCategories: 'landmark/getbillingcategories',
            updateCategoryById: 'landmark/updatecategorybyid'
        };
    }

    areaController() {
        return {
            search: 'area/search?searchkey=',
            getArea: 'area',
            getAreaByPincode: 'area/getareabypincode',
            deleteAreaByCity: 'area/removeareaunderlocation',
            getAreasList: 'area/areaslist'
        };
    }

    alertTypesController() {
        return {
            getAll: 'alerttypes'
        };
    }

    adhocTypesController() {
        return {
            getAdhocType: 'adhoctypes',
            getAllAdhocType: 'adhoctypes/getadhoctype',
            addAdhocType: 'adhoctypes',
            editAdhocType: 'adhoctypes',
            deleteAdhocType: 'adhoctypes?id='
        };
    }

    adhocApproverController() {
        return {
            getAdhocApprover: 'adhocapprover',
            addAdhocApprover: 'adhocapprover',
            editAdhocApprover: 'adhocapprover',
            deleteAdhocApprover: 'adhocapprover?id='
        };
    }

    adhocRequesterController() {
        return {
            getAdhocRequester: 'adhocrequester',
            addAdhocRequester: 'adhocrequester',
            editAdhocRequester: 'adhocrequester',
            deleteAdhocRequester: 'adhocrequester?id='
        };
    }

    scheduleController() {
        return {
            getAll: 'booking/getall?',
            addBookingSetup: 'bookingsetup',
            addBooking: 'booking',
            addAdhocBooking: 'adhocbooking/submitadhocbookings',
            addAdhocBooking1: 'adhocbooking/submitadhocbookings1'
        };
    }

    excelDataUpload() {
        return {
            uploadExcel: 'upload',
            uploadCovidExcel: 'covid/upload',
            uploaduserfields: 'user/uploaduserfields'
        };
    }

    businessUnitController() {
        return {
            getAll: 'businessunitcode',
            add: 'businessunitcode'
        };
    }

    smsSetupController() {
        return {
            getAll: 'smssetup',
            add: 'smssetup',
            update: 'smssetup',
            delete: 'smssetup?id=',
            enableDisable: 'smssetup/enabledisable'
        };
    }

    emailSetupController() {
        return {
            getAll: 'emailsetup',
            add: 'emailsetup',
            update: 'emailsetup',
            delete: 'emailsetup?id=',
            enableDisable: 'emailsetup/enabledisable'
        };
    }

    notificationSetupController() {
        return {
            getAll: 'notificationsetup',
            add: 'notificationsetup',
            update: 'notificationsetup',
            delete: 'notificationsetup?id=',
            enableDisable: 'notificationsetup/enabledisable'
        };
    }

    TrackingServiceController() {
        return {
            gettriptrace: 'gettriptrace',
            tripbyemp: 'tripbyemp?empid=',
            geteta: 'geteta'
        };
    }

    eventController() {
        return {
            getAllEventByModuleId: 'event/geteventbymoduleid?moduleId=',
            add: 'event',
            addSafeDrop: 'safedrop/addSetup',
            enableDisableSetup: 'safedrop/enabledisablesetup',
            getAllSetup: 'safedrop/getallsetup',
            createSafeDropReport: 'safedrop/safedropreport',
            safeDropSummary: 'safedrop/safedropsummary',
            editSetup: 'safedrop/editsetup'
        };
    }

    escallationMatrixController() {
        return {
            getAllAdmin: 'panicsetup',
            deleteAdmin: 'panicsetup/admin?escalationId=',
            addAdmin: 'escallation/escallationAdd',
            updateAdmin: 'escallation',
            getAllOther: 'panicsetup/other',
            deleteOther: 'panicsetup/other?escalationId=',
            addOther: 'escallation/escallationAdd',
            updateOther: 'escallation',
            enableDisableEscalation: 'panicsetup/enabledisableescalation',
            enableDisableEscalationOther: 'panicsetup/enabledisableescalationvendor',
            addPanic: 'panicsetup/admin',
            getAudit: 'escallationmatrix/audit?id='

        };
    }

    categoryController() {
        return {
            getAll: 'category'
        };
    }

    globalSetupController() {
        return {
            getCountry: 'country',
            getCurrency: 'currency',
            getDateFormat: 'dateformat',
            getTimeZone: 'timezone',
            getGlobalSetup: 'globalsetup',
            addGlobalSetup: 'globalsetup',
            updateGlobalSetup: 'globalsetup',
            deleteGlobalSetup: 'globalsetup?id=',
            enableDisable: 'globalsetup/enabledisable'
        };
    }

    menuController() {
        return {
            getAllMenusPageable: 'menu',
            getMenusList: 'menu/getmenus',
            addMenu: 'menu',
            updateMenu: 'menu',
            deleteMenu: 'menu?id=',
            enableDisable: 'menu/enabledisable'
        };
    }

    serverPathController() {
        return {
            getAllServerPath: 'serverpath',
            getServerPathByMenusId: 'serverpath/getserverpathbymenuid?menuid=',
            addServerPath: 'serverpath',
            updateServerPath: 'serverpath',
            audit: 'serverpath/audit?id=',
            getServerPathById: 'serverpath/get?serverpathid=',
            deleteServerPath: 'serverpath?id=',
            enableDisable: 'serverpath/enabledisable'
        };
    }

    tempUserController() {
        return {
            addTempUser: 'newuser/addtempuser',
            getTempUser: 'newuser/gettempuserbyemail?emailid=',
            getlocationlist: 'newuser/getlocationlist',
            getsiteslist: 'newuser/getsiteslist?locationId=',
            getAreaslist: 'newuser/getareaslist?locationId=',
            getLandmarkslist: 'newuser/getlandmarkslist?areaId=',
            getUserById: 'newuser/getbyuserinfobyemail?emailid=',
            userRegistration: 'newuser/userregistration',
            updateUser: 'newuser/updateuser',
            forgotPassword: 'newuser/forgotpassword?emailid=',
        };
    }

    masterzoneController() {
        return {
            masterZone: 'masterzone',
            zone: 'zone',
            zonerename: 'zone/rename',
            cloneMasterZone: 'masterzone/clone',
            updateshiftmap: 'masterzone/updateshiftmap',
            getzoneshiftmap: 'masterzone/getzoneshiftmap',
            getbyid: 'masterzone/getbyid',
            getZones: 'zone',
            getzonebyshift: 'masterzone/getzonebyshift',
            getzonebyid: 'zone/zoneByid',
            getMasterZonesBySite: 'masterzone?siteid=',
            getZonesByMasterZone: 'zone?masterzoneid=',
        };
    }

    escortController() {
        return {
            getAll: 'escort/getescort',
            add: 'escort',
            update: 'escort',
            delete: 'escort?id=',
            enabledisable: 'escort/enabledisableescort',
            getuploaderror: 'escort/getuploaderror',
            audit: 'escort/audit?id=',
            getEscortListBySiteId: 'escort/getescortlist',
            addingEscortToTrip: 'tripdetails/addingescort'

        };
    }

    routingConfigController() {
        return {
            getAllRoutingConfigBylocationId: 'routingconfig/getroutingconfigbylocationid',
            add: 'routingconfig',
            update: 'routingconfig',
            audit: 'routingconfig/audit?id=',
            enabledisable: 'routingconfig/enabledisableroutingconfig',
            tripDetailsGenerate: 'tripdetails/generate',
            getRoutingConfigBySiteId: 'routingconfig/routingcongigbysiteid',
            autoPullLandmarks: 'zone/doautopull',
            getAdjustmentTrips: 'adjustmenttrip/getalladjustmenttrips',
            adjustmentTrip: 'adjustmenttrip',
            updateAdjustmentTrip: 'adjustmenttrip',
            accidentalStop: 'tripdetails/accidentalstop',
            getDeviceTripStatus: 'tripdetails/getdevicetripstatus'
        };
    }

    shiftPickPointMap() {
        return {
            add: 'shiftpickpointmap',
            get: 'shiftpickpointmap/get'
        };
    }

    roleDelegationController() {
        return {
            getDelegatedRoleByUserId: 'roledelegation/getdelegatedrolebyuserid',
            getEmpUnderSpoc: 'user/getusersunderspoc',
            getEmpBySpocId: 'user/getusersunderspoc',
            add: 'roledelegation/addroledelegation',
            update: 'roledelegation/updateroledelegation',
            view: 'roledelegation/getroledelegateddetails'
        };
    }

    roasterController() {
        return {
            get: 'booking/isweeklyroasteropened',
            getWeekly: '/booking/getweeklyroaster',
            getAlter: '/booking/getalterroaster',
            saveRoaster: 'booking/submitweeklyroaster',
            saveAlter: 'booking/submitbookings'
        };
    }

    routePriorityController() {
        return {
            getAll: 'routepriority'
        };
    }

    tripcontroller() {
        return {
            addEscortRateSetup: 'escortratesetup',
            getAll: 'tripdetails',
            generateMirroring: 'tripdetails/generatemirroring',
            saveMirroring: 'tripdetails/savemirroring',
            isNightShift: 'tripdetails/isnightshift',
            getunassignedtrips: 'tripdetails/getunassignedtrips',
            // tripstats: "tripdetails/tripstats",
            tripstats: 'tripdetails/tripstatsshow',
            audit: 'tripdetails/audit?id=',
            allocatevendor: 'tripdetails/allocatevendor',
            allocatevendorbyvehicletyperatio: 'tripdetails/allocatevendorbyvehicletyperatio',
            gettripbytripid: 'tripdetails/gettripbytripid',
            gettripsbytripids: 'tripdetails/gettrips',
            modifytrip: 'tripdetails/modifytrip',
            deletetrip: 'tripdetails/deletetrips',
            getgeneratedtripcount: 'tripdetails/getgeneratedtripcount',
            getVendorAssignedTrips: 'tripdetails/getVendorAssignedTrips',
            updateVendorTripAssign: 'tripdetails/updatetripassign',
            updateTripVendorAllocations: 'tripdetails/updatetripVendorallocations',
            sendBackToAdmin: 'tripdetails/trippushback',
            updateTripTravelTime: 'tripdetails/updatetriptraveltime',
            updatetripassignlist: 'tripdetails/updatetripassignlist',
            getallmasterroute: 'tripdetails/getallmasterroute',
            saveallmasterroute: 'tripdetails/saveallmasterroute',
            saveTripMirrorTemp: 'tripdetails/savetempmirroring',
            deletemasterroute: 'tripdetails/deletemasterroute',
            gettripuploaderrorlist: 'tripdetails/gettripuploaderrorlist?fileid=',
            getTripSummary: 'tripdetails/tripstatssummary',
            getAllException: 'tripdetails/getAllException',
            showshiftwisetrip: 'tripdetails/showshiftwisetrip',
            showspecialbookingtrips: 'tripdetails/showspecialbookingtrips',
            showRunningTrip: 'tripdetails/showrunningtrip',
            showSingleRunningTrip: 'tripdetails/showsinglerunningtrip',
            getZoneByTripId: 'tripdetails/getzonebytripid',
            getZonesByMasterZoneId: 'zone/getrelatedzonesbymasterzoneidandsiteidandlocationid',
            deleteVendorAllocation: 'tripdetails/getallvendorassignedtrips',
            deleteAllocatedtrip: 'tripdetails/deletevendorassign',
            updateTripTotalTime: 'tripdetails/updateemptraveltime',
            updateTravelShowStatus: 'tripdetails/updatetravelshowstatus',
            resetTripStatus: 'tripdetails/resettripstatus',
            resendOtp: 'tripdetails/sendotp'
        };
    }

    livetrackingcontroller() {
        return {
            fetcheta: 'livetracking/fetcheta',
            filterlivetracking: 'livetracking/showshiftwiselivetrip',
            fetchuserhomepickup: 'livetracking/fetchuserhomepickup',
            getallemppickupdropdetails: 'livetracking/getemppickupdropdetails',
            showalltripsforreplay: 'livetracking/showalltripsforreplay',
            // getreplaysummary: 'livetracking/getreplaysummary',
            getreplaysummary: 'livetracking/getreplayreport',
            getreplaysummarydata: 'livetracking/getreplaysummarydata',
        };
    }

    trippredictioncontroller() {
        return {
            tripprediction: 'tripprediction',
            predictmirroring: 'tripprediction/predictmirroring',
            getPrediction: 'tripprediction/getprediction',
            savePredicted: 'tripprediction/savepredicted'
        };
    }

    driverDeviceController() {
        return {
            update: 'driverdevice/updatconfig',
            addNewConfig: 'driverdevice/addconfig',
            getAllStatus: 'driverdevice/getconfigdata',
        };
    }

    specialBookingController() {
        return {
            getAll: 'specialbooking',
            add: 'specialbooking',
            update: 'specialbooking',
            audit: 'specialbooking/audit?id=',
            getSpecialBookingForVendor: 'specialbooking/getlist?userid=',
            getSpecialBookingCanceldByVendor: 'specialbooking/getlistcanceledbyvendor?userid='
        };
    }


    panicActionController() {
        return {
            update: 'panicaction',
            updatePanicActionForAdmin: 'panicaction/updatePanicAction',
            isPanicActionTakenByAdmin: 'panicaction/panicactiontakenbyadmin?siteId=',
            isPanicActionTakenByVendor: 'panicaction/audit/panicactiontakenbyvendor?id=',
            getPanicStopTrips: 'panicaction/panicstoptrips',
            approvePanicStopTrip: 'panicaction/approvepanicstoptrip',
            getPanicActiveStatusBySiteId: 'panicaction/getPanicActiveStatusBySiteId',
            getEmployeePanicbytripid: 'employeepanic/getemployeepanicbytripid',
        };
    }


    dieselVariationsController() {
        return {
            getAllDieselVariationsByFromDateAndToDate: 'dieselvariation',
            addDieselVariation: 'dieselvariation'
        };
    }

    specialCategorySetupController() {
        return {
            getAll: 'specialCategorySetup',
            add: 'specialCategorySetup',
            update: 'specialCategorySetup',
            getspecialcategorybysiteidandspecialcategoryid: 'specialCategorySetup/getspecialcategorybysiteidandspecialcategoryid'
        };
    }

    vehicleRouteMapSetupController() {
        return {
            getAll: 'vehicleroutemap',
            add: 'vehicleroutemap',
            update: 'vehicleroutemap',
            getvehicleroutemapbysiteidandzoneid: 'vehicleroutemap/getvehicleroutemapbysiteidandzoneid'
        };
    }


    fixedRouteController() {
        return {
            getFixedRouteTemplate: 'route/getrouteexcelformat',
            getRoutes: 'route/downloadroutes',
            uploadRoutes: 'route/uploadroutes',
            getroutebysiteidandtype: 'route/getroutebysiteidandtype',
            getAreaByRouteId: 'route/getareabyrouteid',
            getLandmarksByAreaId: 'route/getlandmarksbyareaid',
            deletelandmarkfromroute: 'route/deletelandmarkfromroute',
            deleteareafromroute: 'route/deleteareafromroute',
            deleteroutebyrouteid: 'route/deleteroutebyrouteid',
            addRoute: 'route',
            getlandmarksbylocationidandtype: 'route/getlandmarksbylocationidandtype',
            addlandmarkstoroute: 'route/addlandmarkstoroute',
            getareabylocationid: 'route/getareabylocationid',
            addareastoroute: 'route/addareastoroute'
        };
    }

    auditController() {
        return {
            getEscortRateSetupAuditById: 'escortratesetup/getescortratesetupaudit',
            getShiftAudit: 'audit/getshiftaudit',
            getSiteAudit: 'audit/getsiteaudit',
            getLocationAudit: 'audit/getlocationaudit',
            getDeviceConfigAudit: 'audit/getdeviceconfigaudit',
            getRoleAudit: 'audit/getroleaudit',
            getMenuAudit: 'audit/getmenuaudit',
            getServerPathAudit: 'audit/getserverpathaudit',
            getMasterConfigurationAudit: 'audit/getmasterconfigurationaudit',
            getCompanyAudit: 'audit/getcompanyaudit',
            getSMSSetupAudit: 'audit/getsmssetupaudit',
            getEmailSetupAudit: 'audit/getemailsetupaudit',
            getHolidaySetupAuditById: 'audit/getholidaylistaudit',
            getMapSetupAudit: 'audit/getmapsetupaudit',
            getLateNightBookingSetupAudit: 'audit/getlatenightbookingsetupaudit',
            getProjectSetupAuditById: 'audit/getprojectsetupaudit',
            getPayRollSetupAuditById: 'audit/getpayrollconfigaudit',
            getPWSetupAuditById: 'audit/getcustomauthdetailsaudit',
            getPanicSetupForAdminAuditById: 'audit/getpanicsetupforadminaudit',
            getPanicSetupForVendorAuditById: 'audit/getpanicsetupforvendoraudit',
            getSubscriptionAuditById: 'audit/getsubscriptionaudit',
            getEscortAuditById: 'audit/getescortaudit',
            getTimeSlotsAuditById: 'audit/gettimeslotsaudit',
            getDriverAuditById: 'audit/getdriversaudit',
            getSpecialBookingAuditById: 'audit/getspecialbookingaudit',
            getAdhocBookingAuditById: 'audit/getadhocbookingaudit',
            getLateNightBookingAuditById: 'audit/getlatenightbookingaudit',
            getVehicleTypeAuditById: 'audit/getvehicletypegaudit',
            getVehicleAuditById: 'audit/getvehicleaudit',
            getNotificationSetupAuditById: 'audit/getnotificationsaudit',
            getBookingSetupAuditById: 'audit/getbookingsetupaudit',
            getAdhocBookingSetupAuditById: 'audit/getadhocbookingsetupaudit',
            getSubscriptionSetupAuditById: 'audit/getsubscriptionsetupaudit',
            getVendorMasterAuditById: 'audit/getvendormasteraudit',
            getVendorAuditById: 'audit/getvendoraudit',
            getUserInfoDetailsAuditById: 'audit/getuserinfodetailsaudit',
            getRoleDelegationAuditById: 'audit/getroledelegationaudit',
            getRoutingConfigAuditById: 'audit/getroutingconfigaudit',
            getTripViewAuditById: 'audit/gettripviewaudit',
            getBillingApprovalAuditById: 'audit/getbillingapprovalaudit',
            getTripMirroringSetupAuditById: 'audit/gettripmirroringsetupaudit',
            getBookingViolationAudit: 'audit/getbookingviolationaudit',
            getSuspendSubscriptionAudit: 'audit/getSuspendSubscriptionAudit',
            getVendorAllocationSetupAuditById: 'audit/getvendorallocationsetupaudit',
            getLandmarkAudit: 'audit/landmarkaudit?id='
        };
    }

    timeslotsController() {
        return {
            search: 'timeslot/search?searchkey=',
            updateTimeSlot: 'timeslot',
            getTimeSlotByName: 'timeslot/gettimeslotsbyname',
            deleteTimeSlots: 'timeslot/updatetimeslotstatus',
            getTimeSlotList: 'timeslot/timeslotlist'
        };
    }

    atomTicketController() {
        return {
            atomTicketStatusList: 'atomticket/getatomticketstatus',
            atomTicket: 'atomticket',
            approveAtomTicket: 'atomticket/approveatomticket',
            getAtomTicket: 'atomticket/getatomticketsforclient',
            getRemarksByTicketId: 'atomticket/getremaksbyticketid',
            addRemarksForticket: 'atomticket/addremark',
            getAtomUsersList: 'atomticket/getatomemployeeslistbydeveloperrole',
        };
    }

    searchAPIsController() {
        return {
            getreportingofficerslist: 'search/getreportingofficerslist',
            getspocuserslist: 'search/getspocuserslist',
            getlocationlist: 'search/getlocationlist',
            getsiteslist: 'search/getsiteslist',
            getprojectslist: 'search/getprojectslist',
            getarealist: 'search/getarealist',
            getcommonpointlandmarkslist: 'search/getcommonpointlandmarkslist',
            getrolelist: 'search/getrolelist',
        };
    }

    tripMirroringSetupController() {
        return {
            getAll: 'tripmirroringsetup',
            add: 'tripmirroringsetup',
            update: 'tripmirroringsetup',
            delete: 'tripmirroringsetup?tripmirroringsetupid=',
            audit: 'tripmirroringsetup/audit?id=',
            template: 'tripmirroringsetup/site',
            enabledisablemirroringsetup: '/tripmirroringsetup/enabledisablemirroringsetup'
        };
    }

    vehicleInspectionDayController() {
        return {
            getAll: 'vehicleinspection',
            getVehicleInspectionDayList: 'vehicleinspectiondays',
            updateVehicleInspectionDay: 'vehicleinspection'

        }
    }

    vehicleHygieneController() {
        return {
            getAll: 'vehiclehygiene',
            getVehicleHygineList: 'hygieneinspectiondays',
            updateVehicleHygiene: 'vehiclehygiene'
        }
    }

    vehicleCheckList() {
        return {
            add: 'vehiclechecklist',
            getVehicleChecklistBySiteId: 'vehiclechecklist/getbysiteid',
            getAll: 'vehiclechecklist'
        };
    }

    vehicleInspectionDaySetupController() {
        return {
            getVehicleInspectionDayListBySiteId: 'vehicleinspectionsetup/getbysiteid',
            updateVehicleInspectionDay: 'vehicleinspectionsetup/updateinspectionday',
            add: 'vehicleinspectionsetup/addinspectionday'
        }
    }

    vehicleHygieneDaySetupController() {
        return {
            getVehicleHygieneDayListBySiteId: 'hygieneinspectiondaysetup/getbysiteid',
            updateVehicleHygieneDay: 'hygieneinspectiondaysetup/updatehyginesetup',
            add: 'hygieneinspectiondaysetup/addhyginesetup'
        }
    }

    vendorAllocationSetupController() {
        return {
            getAll: 'vendorallocationsetup',
            add: 'vendorallocationsetup',
            update: 'vendorallocationsetup',
            delete: 'vendorallocationsetup?vendorallocationsetupid=',
            audit: 'vendorallocationsetup/audit?id=',
            template: 'vendorallocationsetup/site',
            enabledisablevendorallocationsetup: '/vendorallocationsetup/enabledisablevendorallocationsetup'
        };
    }

    vehicleCheckListSetupController() {
        return {
            getAll: 'vehiclechecklistsetup',
            getBySiteId: 'vehiclechecklistsetup/getbysiteid',
            addAll: 'vehiclechecklistsetup/addAll',
            add: 'vehiclechecklistsetup/add',
            update: 'vehiclechecklistsetup'
        }
    }

    vehicleSiteChecklistController() {
        return {
            getBySiteId: 'vehiclesitechecklist',
            add: 'vehiclesitechecklist',
            update: 'vehiclesitechecklist'
        }
    }

    billingController() {
        return {
            addAlertsAndWishes: 'alertsw',
            allSetupBilling: 'billingsetup/getallsetup',
            getBillingType: 'billing/getbillingtypes',
            addBillingSetup: 'billingsetup',
            editBillingSetup: 'billingsetup/editsetup',
            addSlabSetup: 'billingsetup/slab',
            editSlabSetup: 'billingsetup/slab',
            getAllSlabSetup: 'billingsetup/getslabconfig',
            getVehicleTypeBySiteId: 'vehicletype/getvehicletypebysite',
            billingReport: 'report/getbillingreport',
            auditBillingSetup: 'audit/getbillingsetupaudit',
            auditSlab: 'audit/getslabwiseaudit',
            detailReport: 'report/getbillingdetails',
            getOncallBilling: 'oncallbillingsetup/getoncallbillingsetup',
            getOnCallBillingType: 'oncallbillingsetup/getoncallbillingtypes',
            addOnCallBillingSetup: 'oncallbillingsetup/addoncallbillingsetup',
            updateOnCallBillingSetup: 'oncallbillingsetup/editoncallbillingsetup',
            getSlabWiseSetup: 'oncallbillingsetup/getoncallbillingslabsetup',
            addEditSlabWiseSetup: 'oncallbillingsetup/addeditoncallbillingslab',
            getAllPackageSetup: 'oncallbillingsetup/getoncallbillingpackages',
            addPackageWiseSetup: 'oncallbillingsetup/addoncallbillingpackage',
            editPackageWiseSetup: 'oncallbillingsetup/editoncallbillingpackage',
            onCallBillingVehicleType: 'oncallbillingsetup/getallvehicletypes',
            oncallBillingReport: 'report/getoncallbillingreport',
            oncallDetailReport: 'report/getoncallbillingdetails',
            getBillingAccess: 'billing/getbillingaccess',
            updateAndApproveTollTax: 'billingsetup/updatetolltax',
            getLastBillingDate: 'adminDashboard/lastBilledDate',
            getSummaryCount: 'adminDashboard/overallBillingStatistics',
            getTripCount: 'adminDashboard/tripWiseBillingStatistics',
            getVendorGraph: 'adminDashboard/vehicleTypeWiseExpenditures',
            getShiftsDashboardData: 'adminDashboard/shiftWiseBillingStatistics',
            getKmBasedSetup: 'billingsetup/getKmBasedSetup',
            addKmBasedSetup: 'billingsetup/addKmBasedSetup',
            editKmBasedSetup: 'billingsetup/editKmBasedSetup',
            uploadTollandTaxFile: 'billingsetup/uploadTollAndTaxRateDoc',
            auditOnCallBillingSetup: 'audit/getoncallbillingsetupaudit',
            auditOnCallBillingSlabWiseSetup: 'audit/getoncallbillingslabwisesetupaudit',
            auditOnCallBillingPackageWiseSetup: 'audit/getoncallbillingpackagewisesetupaudit',
            auditKmBasedSetup: 'audit/getkmbasedsetupaudit'
        }
    }

    tripsController() {
        return {
            getotaotdfordashboard: 'dashboard/getotaotdfordashboard',
            getNoOfTripsPerDay: 'dashboard/getNoOfTripsPerDay',
            gettripbyshiftlogtype: 'dashboard/gettripbyshiftlogtypefordashboard',
            gettotaltripbysite: 'dashboard/gettotaltripbysitefordashboard',
            gettotaltripbyvendor: 'dashboard/gettotaltripbyvendorfordashboard',
            gettotaltripcountwithamount: 'dashboard/gettotaltripcountwithamountfordashboard'
        }
    }

    employeeController() {
        return {
            getschedulemalefemalecountfordashboard: 'dashboard/getschedulemalefemalecountfordashboard',
            getEmployeeTripsDashboard: 'dashboard/getEmployeeTripsDashboard'
        }
    }

}