import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';


@Injectable({ providedIn: 'root' })
export class SnackbarUtil {

    constructor(private snackBar: MatSnackBar) { }

    openSnackBar(message: string, action: string) {

        this.snackBar.open(message, action, {
            duration: 5000,
            //verticalPosition:'top',
            horizontalPosition: 'center'
        });
    }

    openFileUploadSnackBar(message: string, action: string) {
        let duration;
        if (!action)
            duration = 5000
        this.snackBar.open(message, action, {
            duration: duration,
            panelClass: ['file-upload-status-container'],
            verticalPosition: 'top',
            horizontalPosition: 'right'
        });
    }

    openSnackBarLogin(message: string, action: string) {

        this.snackBar.open(message, action, {
            duration: 5000,
            verticalPosition: 'top',
            horizontalPosition: 'center'
        });
    }
}