<header id="page-topbar">
  <div class="navbar-header">
    <div class="d-flex">
      <div class="navbar-brand-box">
        <span class="logo-lg">
          <img src="assets/images/billing_logo.png" class="billing-img ms-2" alt="" height="25"></span>
      </div>
    </div>

    <div class="d-flex">
      <div class="dropdown d-inline-block" ngbDropdown>
        <a class="text-danger " href="javascript: void(0);" (click)="logout()"><i
            class="bx bx-power-off font-size-16 align-middle me-1 text-danger"></i> Log Out</a>
      </div>
    </div>
  </div>
</header>